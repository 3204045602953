import React from "react";

export type FormDeclinedMessageProps = {
  status?: "PENDING" | "APPROVED" | "DECLINED" | string;
  admin_note?: string;
}
export default function FormDeclinedMessage(props: FormDeclinedMessageProps) {
  if (!props.status || props.status !== "DECLINED") {
    return (<></>);
  }

  const noteText = props.admin_note ? "The following note was left:" : "No note was left.";

  return (<div className={`mb-10 alert alert-custom alert-danger alert-dismissible`}>
    <div className="alert-text font-weight-bold">
      <div>
        This listing has been declined by an admin.
        {noteText}
      </div>
      {props.admin_note && (
        <div>
          {props.admin_note}
        </div>
      )}
    </div>
  </div>);
}