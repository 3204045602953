import * as Yup from "yup";
import { IMAGE_TYPES } from "../../constraints";
import { ListingTypes } from "../../modules/Auth/_redux/authCrud";
import {
  StrapiCategoryResponse,
  StrapiIndustryResponse,
  StrapiLocationResponse,
  StrapiSubcategoryResponse
} from "../../modules/Strapi/types/StrapiResponses";
import Strapi from "../../modules/Strapi/Strapi";
import { connectRelation, convertRelation } from "../../modules/Strapi/Func";
import { convertCategory, convertSubcategory } from "../../modules/Strapi/ApiConverter";
import {
  ConvertedCategoryResponse,
  ConvertedMiniResponse,
  ConvertedSubcategoryResponse
} from "../../modules/Strapi/types/ConvertedTypes";
import React from "react";
import { User } from "../../modules/Strapi/User";

const logoSchema = Yup
  .mixed()
  .test(
    "fileFormat",
    "Unsupported Format: Must be JPEG or PNG",
    () => {
      const fileInput = document.getElementById("listing-form-logo") as HTMLInputElement;
      if (fileInput) {
        const files = Array.from(fileInput.files || [] as any as FileList);
        const badFiles = files.filter((f) => !IMAGE_TYPES.includes(f.type));
        return badFiles.length === 0;
      }
      return false;
    }
  );


export const CommonView = (view: "apps" | "services" = "apps") => {
  let initialValues = {
    name: "",
    short_description: "",
    long_description: "",
    link: "",
    free_trial: "",
    pricing: "",
    status: "PENDING",
    admin_note: ""
  } as Record<string, string | boolean>;

  let schema = {
    name: Yup.string().required("Listing name is required"),
    short_description: Yup.string().required("Short description is required"),
    long_description: Yup.string().required("Full description is required"),
    link: Yup.string().url("Must be a valid link").required("Link is required"),
    free_trial: Yup.string().url("Must be a valid link"),
    pricing: Yup.string().required("Pricing is required"),
    industries: Yup.array(Yup.string()),
    logo: logoSchema,
    nz_owned: Yup.boolean()
  } as Record<string, any>;


  if (view == "apps") {
    initialValues = {
      ...initialValues,
      nz_owned: false
    };

    schema = {
      ...schema,
      subcategories: Yup.array(Yup.number())
    };
  } else if (view === "services") {
    initialValues = {
      ...initialValues,
      email: "",
      phone: ""
    };

    schema = {
      ...schema,
      categories: Yup.array(Yup.string()),
      locations: Yup.array(Yup.string()),
      email: Yup.string(),
      phone: Yup.string()
    };
  }


  return {
    initialValues: initialValues,
    Schema: Yup.object().shape(schema)
  };
};

export type HandleSubmitProps = {
  strapi: Strapi
  nzOwned?: boolean,
  isNewListing?: boolean,
  isService?: boolean,
  listing: ListingTypes,
  submitHandler: (res?: any) => any,
  setStatus: (s: string) => void,
  setMessage: (r: { type: string; message: string; }) => void,
  setSubmitting: (b: boolean) => void,
  formik: any
}

export const handleSubmit = async (opts: HandleSubmitProps) => {
  const user = await User.fetchUser(opts.strapi);
  if (!user || !user.account) {
    opts.setStatus("error");
    opts.setMessage({
      type: "danger",
      message: "User error, please reload the page and try again"
    });
  }

  const accountId = user.account as number;

  opts.setSubmitting(true);

  const form = document.getElementById("listing-form") as HTMLFormElement;
  const formLogo = document.getElementById("listing-form-logo") as HTMLInputElement;

  const formData = new FormData(form);
  const data = opts.formik.values;
  const extraData = opts.listing as Record<string, any>;

  const getVal = (key: string) => {
    if (extraData[key]) {
      return extraData[key];
    }

    if (data[key]) {
      return data[key];
    }

    if (formData.get(key)) {
      return formData.get(key);
    }

    return null;
  };

  // Remove logo from form submission, this is done separately
  delete data.logo;

  let image;
  if (formLogo.value && formLogo.files && formLogo.files.length > 0) {
    image = await opts.strapi.upload.post(formLogo.files[0]);
  }

  // Strapi uses different keys, so we will convert here
  const payload: Record<string, any> = {
    name: data.name,
    summary: data.short_description,
    description: data.long_description,
    link: data.link,
    trial: data.free_trial ?? null,
    pricing: data.pricing ?? null,
    industries: connectRelation(getVal("industries")),
    nz: opts.nzOwned,
    account: connectRelation(accountId),
    note: data.admin_note ?? ""
  };

  if (opts.isService) {
    // Service
    payload.locations = connectRelation(getVal("locations"));
    payload.categories = connectRelation(getVal("categories"));
    payload.email = data.email ?? null;
    payload.phone = data.phone ?? null;
  } else {
    // App
    payload.subcategories = connectRelation(getVal("subcategories"));
  }

  if (image) {
    payload.logo = image.id;
  }

  // Get collection
  const collection = opts.strapi.collection((opts.isService ? "services" : "apps"));
  (opts.isNewListing ?
      collection.post(payload) :
      collection.put(opts.listing.id, payload)
  )
    .then(async (data) => {
      opts.setSubmitting(false);
      opts.setStatus("success");
      opts.setMessage({
        type: "success",
        message: (user && user.staff) ? "Listing updated." : "Listing successfully submitted for review."
      });

      if (data && data.data) {
        return data.data.data;
      }
    })
    .then(opts.submitHandler)
    .catch((err) => {
      opts.setSubmitting(false);
      opts.setStatus(err);
      if (err.smart) {
        opts.setMessage({
          type: "danger",
          message: "Could not create or update listing. See below for more information."
        });
        Object.entries(err.json as Array<[string, string | string[]]>).forEach(([key, value]) => {
          opts.formik.setFieldError(key, typeof value === "string" ? value : value.join(" "));
        });
      } else {
        opts.setStatus(err.message);
        opts.setMessage({
          type: "danger",
          message: err.message
        });
      }
    });
};

/*
Suggested usage:
const getInputClasses = (fieldName: string, disableTouch?: boolean) => {
  return commonGetInputClasses(formik, fieldName, disableTouch)
};
 */
export const commonGetInputClasses = (formik: any, fieldName: string, disableTouch?: boolean) => {
  if (((formik.touched as any)[fieldName] || disableTouch) && (formik.errors as any)[fieldName]) {
    return "is-invalid";
  }

  if (((formik.touched as any)[fieldName] || disableTouch) && !(formik.errors as any)[fieldName]) {
    return "is-valid";
  }

  return "";
};

interface CommonConvert {
  industries: (value?: StrapiIndustryResponse[]) => ConvertedMiniResponse[];
  categories: (value?: StrapiCategoryResponse[]) => ConvertedCategoryResponse[];
  subcategories: (value?: StrapiSubcategoryResponse[]) => ConvertedSubcategoryResponse[];
  locations: (value?: StrapiLocationResponse[]) => ConvertedMiniResponse[];
}

export const commonConvert: CommonConvert = {
  industries: (value?: StrapiIndustryResponse[]) => convertRelation({ data: value }, "industries"),
  categories: (value?: StrapiCategoryResponse[]) => (value ?? []).map(convertCategory),
  subcategories: (value?: StrapiSubcategoryResponse[]) => (value ?? []).map(convertSubcategory),
  locations: (value?: StrapiLocationResponse[]) => convertRelation({ data: value }, "locations")
};

export const commonStrapiLoad = async <StrapiResponse>(strapi: Strapi, collection: keyof CommonConvert, setter: React.Dispatch<React.SetStateAction<any[]>>) => {
  return await strapi.collection(collection)
    .getAll<StrapiResponse>()
    .then(data => commonConvert[collection](data as any))
    // .then(commonConvert[collection])
    .then(setter);
};