import fetch from "node-fetch";
import { API_BASE } from "../../../constraints";

import { IAppListing } from "../../../../interface/IAppListing";
import { IClaim } from "../../../../interface/IClaim";
import { IIndustry } from "../../../../interface/IIndustry";
import { IReview } from "../../../../interface/IReview";
import { IServiceListing } from "../../../../interface/IServiceListing";
import Strapi from "../../Strapi/Strapi";
import { User } from "../../Strapi/User";

const SUBSCRIPTION_URL = `${API_BASE}/payments/subscription/`;

export async function createSubscription({ token, coupon, term, trial }: {
  token: string;
  coupon?: string;
  term: string;
  trial?: boolean;
}, method?: string): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(SUBSCRIPTION_URL, {
      method: method || "POST",
      headers: {
        Authorization: (window as any).API_TOKEN,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        payment_method_id: token,
        term,
        coupon,
        trial
      })
    })
      .then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          resolve(json);
        } else {
          if (res.status === 404) {
            resolve(404);
          } else {
            reject();
          }
        }
      })
      .catch(() => reject({
        smart: false,
        message: "An error has occurred. Please try again."
      }));
  });
}

export async function getSubscriptionInfo(): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch(SUBSCRIPTION_URL, {
      method: "GET",
      headers: {
        Authorization: (window as any).API_TOKEN,
        "Content-Type": "application/json"
      }
    })
      .then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          resolve(json);
        } else {
          if (res.status === 404) {
            resolve(404);
          } else {
            reject();
          }
        }
      })
      .catch(() => reject({
        smart: false,
        message: "An error has occurred. Please try again."
      }));
  });
}

type ListingOptions = {
  legacyPath?: string,
  pending?: boolean,
  all?: boolean
}

export function getListings<T = IAppListing>(collection: string, convertor: (any: any) => any, listingOptions: ListingOptions): (query: string) => Promise<T[]> {
  return async function getListings(): Promise<T[]> {
    const strapi = await Strapi.connect();
    const user = await User.fetchUser(strapi);

    if (!user || !user.loaded) {
      return [];
    }

    const col = strapi.collection(collection);

    if (listingOptions.pending) {
      col.addFilter("status", { $ne: "APPROVED" });
      col.showDrafts();
    } else {
      col.addFilter("status", { $eq: "APPROVED" });
    }

    if (!user.admin || !listingOptions.all) {
      col.addFilter("account", {
        id: { $eq: user.account }
      });
    }

    return await col.getAll()
      .then(listings => {
        if (!listings) {
          return [];
        }
        return Promise.all(listings.map(async listing => convertor(listing)));
      });
  };
}

export async function getListing<T = IAppListing>(listingId: string): Promise<T> {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE}${listingId}`, {
      method: "GET",
      headers: {
        Authorization: (window as any).API_TOKEN,
        "Content-Type": "application/json"
      }
    })
      .then(async (res) => {
        const json = await res.json();
        if (res.ok) {
          resolve(json);
        } else {
          reject();
        }
      })
      .catch(reject);
  });
}

export type ListingTypes = IAppListing | IServiceListing | IReview | IClaim | IIndustry
