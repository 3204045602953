// Simple user class to use computed props
import { StrapiAccountResponse, StrapiAuthenticatedUser, StrapiUserResponse } from "./types/StrapiResponses";
import Strapi from "./Strapi";
import Store from "../../services/Store";
import Cookies from "../../services/Cookies";
import { UserCache } from "./UserCache";
import { StrapiBase } from "./StrapiBase";
import { redirect } from "./Func";

export class User extends StrapiBase {
  private static instance: User | null = null;
  public readonly blocked: boolean | undefined;
  public readonly confirmed: boolean | undefined;
  public readonly email: string | undefined;
  public readonly id: number | undefined;
  public readonly provider: string | undefined;
  public readonly username: string | undefined;
  public readonly redirect_url ?: string;
  public readonly newsletter ?: boolean;
  public readonly staff ?: boolean;
  public readonly super ?: boolean;
  public readonly last_login ?: string;
  public readonly first_name ?: string;
  public readonly last_name ?: string;
  public readonly django_id ?: string;
  public readonly account: number | undefined;
  private readonly strapi: Strapi;
  private _stripeData = {
    customer_id: "",
    subscription_id: "",
    payment_id: "",
    upgraded: false
  };
  private _loadUserInProgress: Promise<void> | null = null;

  constructor(strapi: Strapi) {
    super();
    this.strapi = strapi;
  }

  private _loaded = false;

  get loaded(): boolean {
    return this._loaded;
  }

  get admin(): boolean {
    return !!(this.staff || this.super);
  }

  get upgraded(): boolean {
    return this._stripeData.upgraded;
  }

  get customer_id(): string {
    return this._stripeData.customer_id;
  }

  get subscription_id(): string {
    return this._stripeData.subscription_id;
  }

  get trial(): boolean {
    return false;
  }

  get authenticated() {

    return (this.id && this.id !== 0 && !this.blocked && this.confirmed);
  }

  public static async fetchUser(strapi: Strapi | null): Promise<User> {
    if (this.instance === null) {
      this.instance = new User(strapi ?? new Strapi());
      // Attempt to load user
      if (Store.get("AUTH_TOKEN")) {
        await this.instance
          .loadUser()
          .then(() => (this.instance as User).verifyStripe());
      }
    }

    if (!this.instance._stripeData.customer_id && this.instance._loaded) {
      await this.instance.verifyStripe();
    }

    return this.instance;
  }

  public static async register(email: string, password: string, first_name: string, last_name: string, newsletter = false) {
    const strapi = new Strapi();
    const t = (e: unknown) => {
      throw e;
    };

    return await strapi.connection
      .url("/auth/local/register")
      .post({
        username: email,
        email: email,
        password: password,
        first_name,
        last_name,
        newsletter
      })
      .badRequest(err => t(err.response.data.error))
      .fetchError(err => t(err))
      .json(async response => {
        if (response) {
          if (response.jwt) {
            // NB: Can login immediately.
            Store.set("AUTH_TOKEN", `Bearer ${response.jwt}`, 7);
            Cookies.set("AUTH_TOKEN", `Bearer ${response.jwt}`, 7);
            const user = new User(strapi);
            user.setUser(User.mergeStrapiDetails(response.account, response.user));
            return user;
          } else {
            // Need to verify email
            redirect(`/auth/login?message=register-success&username=${email}`, { skipChecks: true });
          }
        }

        return null;
      });
  }

  public static async login(username: string, password: string) {
    if (!username || !password) {
      throw new Error("Username or pass not provided");
    }

    const strapi = new Strapi();
    return await strapi.connection
      .url("/auth/local")
      .catcher(400, (err) => {
        if (err.status === 400 && err.message) {
          throw new Error("Invalid username or password");
        }
      })
      .post({
        identifier: username,
        password: password
      })
      .json((data) => {
        if (data && data.jwt) {
          Store.set("AUTH_TOKEN", `Bearer ${data.jwt}`, 7);
          Cookies.set("AUTH_TOKEN", `Bearer ${data.jwt}`, 7);

          return User.fetchUser(strapi);
        }

        return null;
      });
  }

  public static async logout() {
    Cookies.remove("AUTH_TOKEN");
    Store.remove("AUTH_TOKEN");
    UserCache.removeUserFromCache();
  }

  private static mergeStrapiDetails(account: StrapiAccountResponse, user: StrapiUserResponse) {
    return {
      ...account,
      account: account.id,
      ...user,
      upgraded: false
    };
  }

  toObject(): StrapiAuthenticatedUser {
    return {
      blocked: this.blocked ?? false,
      confirmed: this.confirmed ?? false,
      email: this.email ?? "",
      id: this.id ?? 0,
      provider: this.provider ?? "local",
      username: this.username ?? "",
      customer_id: this.customer_id,
      subscription_id: this.subscription_id,
      redirect_url: this.redirect_url,
      newsletter: this.newsletter,
      staff: this.staff,
      super: this.super,
      last_login: this.last_login,
      first_name: this.first_name,
      last_name: this.last_name,
      django_id: this.django_id,
      account: this.account ?? 0,
      upgraded: this.upgraded
    };
  }

  public async reload() {
    UserCache.removeUserFromCache();
    return this.loadUser();
  }

  private async verifyStripe() {
    await this.strapi.connection
      .get("/me/stripe")
      .json(results => {
        this._stripeData = {
          customer_id: results.customer,
          subscription_id: results.subscription,
          payment_id: results.payment,
          upgraded: results.active
        };
      })
      .catch(() => {
        this._stripeData = {
          customer_id: "",
          subscription_id: "",
          payment_id: "",
          upgraded: false
        };
      });
  }

  private setUser(user: StrapiAuthenticatedUser) {
    if (!user) {
      return;
    }
    (this as any).blocked = user.blocked;
    (this as any).confirmed = user.confirmed;
    (this as any).email = user.email;
    (this as any).id = user.id;
    (this as any).provider = user.provider;
    (this as any).username = user.username;
    (this as any).redirect_url = user.redirect_url;
    (this as any).newsletter = user.newsletter;
    (this as any).staff = user.staff;
    (this as any).super = user.super;
    (this as any).last_login = user.last_login;
    (this as any).first_name = user.first_name;
    (this as any).last_name = user.last_name;
    (this as any).django_id = user.django_id;
    (this as any).account = user.account;
    this._loaded = true;
  }

  private async loadUser() {
    if (this._loadUserInProgress) {
      return this._loadUserInProgress;
    }

    this._loadUserInProgress = (async () => {
      try {
        const cachedUser = UserCache.getUserFromCache();
        if (cachedUser) {
          this.setUser(cachedUser);
        } else {
          // Attempt to load user from strapi.
          const responseData = await this.strapi.connection.get("/users/me").json();
          const { account, ...user } = responseData;
          const userData = {
            ...account,
            account: account.id,
            ...user
          };
          this.setUser(userData);
          UserCache.setUserInCache(userData);
        }
      } finally {
        this._loadUserInProgress = null;
      }
    })();

    return this._loadUserInProgress;
  }
}