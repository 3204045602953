import React from "react";
import { commonGetInputClasses } from "../../app/pages/Listings/CommonView";
import classnames from "classnames";

type FileInputProps = {
  label: string
  id: string

  formik: any
}

export default function FileInput({ label, id, formik }: FileInputProps) {

  const getFileInputClasses = (fieldName: string, disableTouch?: boolean) => {
    return commonGetInputClasses(formik, fieldName, disableTouch);
  };

  const fileInputClass = classnames(
    "form-control form-control-lg form-control-solid",
    getFileInputClasses(id, true)
  );

  return (
    <div className="form-group row">
      <label className="col-xl-3 col-lg-3 col-form-label">
        {label}
      </label>
      <div className="col-lg-9">
        <input
          type="file"
          accept="image/*"
          className={fileInputClass}
          name="logo"
          id="listing-form-logo"
          onChange={formik.handleChange}
        />
        {formik.errors.logo ? (
          <div className="invalid-feedback">
            {formik.errors.logo}
          </div>
        ) : null}
      </div>
    </div>
  );
}