import React, { useEffect, useState } from "react";
import Select from "react-select";
import { shallowEqual, useSelector } from "react-redux";

import { getListings } from "../../modules/Auth/_redux/authCrud";
import { IServiceListing } from "../../../interface/IServiceListing";
import { IIndustry } from "../../../interface/IIndustry";
import { IAppListing } from "../../../interface/IAppListing";

const MAX_FEATURED_APPS = 6;
const MAX_FEATURED_SERVICES = 6;

export const Featured = () => {
  const user = useSelector(({ auth }: any) => auth.user, shallowEqual);
  useEffect(() => {
    return () => {
      // null
    };
  }, [user]);

  // useSubheader().setTitle("Featured");

  const [industries, setIndustries] = useState<IIndustry[]>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<IIndustry | null>();

  const [apps, setApps] = useState<IAppListing[]>([]);
  const [featuredApps, setFeaturedApps] = useState<IAppListing[]>([]);

  const [services, setServices] = useState<IServiceListing[]>([]);
  const [featuredServices, setFeaturedServices] = useState<IServiceListing[]>([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const saveSelection = () => {
    if (!selectedIndustry) return;
    console.log({
      selectedIndustry,
      featuredServices
    });

    setIsSubmitting(true);

//    patchItem(selectedIndustry, JSON.stringify({
//      featured_apps:
//        featuredApps.map((f) => f.url),
//
//      featured_services:
//        featuredServices.map((f) => f.url)
//    }), {
//      "Content-Type": "application/json"
//    })
//      .then((res: any) => {
//        console.log({ res });
//        setIsSubmitting(false);
//      });
  };

//  React.useEffect(() => {
//    getIndustries()
//      .then((res) => {
//        setIndustries(res);
//      });
//  }, []);

  React.useEffect(() => {
    if (selectedIndustry) {
      const industryParts = selectedIndustry.url.split("/industries/");

      setApps([]);
      setFeaturedApps([]);

      setServices([]);
      setFeaturedServices([]);

      getListings(`/industries/${industryParts[1]}`, (i) => i, {})("")
        .then((res: any) => {
          setApps(res.apps);
          setFeaturedApps(res.featured_apps);

          setServices(res.services);
          setFeaturedServices(res.featured_services);
        });
    }
  }, [selectedIndustry]);

  return (
    <>
      <div
        className="card card-custom mb-0">
        {/* begin::Header */}
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label font-weight-bolder text-dark">
              Featured Listings
            </h3>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
              Bring extra attention to a selected few.
            </span>
          </div>
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-success"
              disabled={isSubmitting || !selectedIndustry || (apps.length === 0 && services.length === 0)}
              onClick={() => saveSelection()}>
              Save changes
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Form */}
        <div className="form">
          {/* begin::Body */}
          <div className="card-body">
            <div className="form-group row mb-0">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Industry
              </label>
              <div className="col-lg-9">
                <Select
                  className="basic-single mb-3"
                  classNamePrefix="select"
                  isLoading={isSubmitting || industries.length === 0}
                  isDisabled={isSubmitting}
                  isClearable={false}
                  isSearchable={true}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={
                    industries
                      .map((app) => ({
                        label: app.name,
                        value: app.url,
                        ...app
                      } as any as IIndustry))
                  }
                  value={selectedIndustry}
                  onChange={(value) => {
                    setSelectedIndustry(value);
                  }}
                />
              </div>
            </div>

            <div className="form-group row mb-0">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Featured apps
                {
                  MAX_FEATURED_APPS > 0 &&
                  <>
                    <br />
                    <em className="text-muted">Max {MAX_FEATURED_APPS}</em>
                  </>
                }
              </label>
              <div className="col-lg-9">
                <Select
                  className="basic-single mb-3"
                  classNamePrefix="select"
                  isLoading={isSubmitting || !!selectedIndustry && apps.length === 0}
                  isDisabled={isSubmitting || !selectedIndustry}
                  isClearable={false}
                  isSearchable={true}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  options={
                    MAX_FEATURED_APPS && featuredApps.length >= MAX_FEATURED_APPS
                      ? []
                      : apps
                        .map((app) => ({
                          label: app.name,
                          value: app.url
                        }))
                  }
                  value={
                    featuredApps
                      .map((app) => ({
                        label: app.name,
                        value: app.url
                      }))
                  }
                  onChange={(values) => {
                    const newApps = values ? values.map((value) => value.value) : [];
                    setFeaturedApps(
                      apps
                        .filter((a) => newApps.includes(a.url))
                    );
                  }}
                  noOptionsMessage={() => {
                    if (MAX_FEATURED_APPS && featuredApps.length >= MAX_FEATURED_APPS) {
                      return `Max ${MAX_FEATURED_APPS} already selected`;
                    }

                    return "No results found";
                  }}
                />
              </div>
            </div>

            <div className="form-group row mb-0">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Featured services
                {
                  MAX_FEATURED_SERVICES > 0 &&
                  <>
                    <br />
                    <em className="text-muted">Max {MAX_FEATURED_SERVICES}</em>
                  </>
                }
              </label>
              <div className="col-lg-9">
                <Select
                  className="basic-single mb-3"
                  classNamePrefix="select"
                  isLoading={isSubmitting || !!selectedIndustry && services.length === 0}
                  isDisabled={isSubmitting || !selectedIndustry}
                  isClearable={false}
                  isSearchable={true}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  options={
                    MAX_FEATURED_SERVICES && featuredServices.length >= MAX_FEATURED_SERVICES
                      ? []
                      : services
                        .map((app) => ({
                          label: app.name,
                          value: app.url
                        }))
                  }
                  value={
                    featuredServices
                      .map((app) => ({
                        label: app.name,
                        value: app.url
                      }))
                  }
                  onChange={(values) => {
                    const newApps = values ? values.map((value) => value.value) : [];
                    setFeaturedServices(
                      services
                        .filter((a) => newApps.includes(a.url))
                    );
                  }}
                  noOptionsMessage={() => {
                    if (MAX_FEATURED_SERVICES && featuredServices.length >= MAX_FEATURED_SERVICES) {
                      return `Max ${MAX_FEATURED_SERVICES} already selected`;
                    }

                    return "No results found";
                  }}
                />
              </div>
            </div>
          </div>
          {/* end::Body */}
        </div>
        {/* end::Form */}
      </div>
    </>
  );
};
