import React, { ReactElement } from "react";
import { useSubheader } from "../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";

import "./ServicesPaywall.scss";
import { useSelector } from "react-redux";
import Plan from "../../../interface/IPlan";

export const TrialPaywall = (): ReactElement => {
  // const suhbeader = useSubheader();
  useSubheader().setTitle("Paywall");
  const plans = useSelector((state: any) => state.auth.plans as Record<any, Plan>);

  const plan = plans.annually;

  return (
    <div className="services-paywall">
      <div className="services-paywall__header">
        <img src={toAbsoluteUrl("/media/svg/paywall-top.png")} className="services-paywall__header__image" />
        <h1 className="services-paywall__header__title">Try it free for 90 days</h1>
        <p className="services-paywall__header__caption">Your listing will give you the opportunity to acquire high
          buying intent web traffic and sales leads from small business owners ready to try and buy your solution.</p>
      </div>

      <ul className="services-paywall__benefits">
        <li className="services-paywall__benefits__item">
          <SVG src={toAbsoluteUrl("/media/svg/svg-tick.svg")} className="services-paywall__benefits__item__tick" />
          <span className="services-paywall__benefits__item__caption">Tailored Leads</span>
        </li>

        <li className="services-paywall__benefits__item">
          <SVG src={toAbsoluteUrl("/media/svg/svg-tick.svg")} className="services-paywall__benefits__item__tick" />
          <span className="services-paywall__benefits__item__caption">Local Reviews</span>
        </li>

        <li className="services-paywall__benefits__item">
          <SVG src={toAbsoluteUrl("/media/svg/svg-tick.svg")} className="services-paywall__benefits__item__tick" />
          <span className="services-paywall__benefits__item__caption">Public Profile</span>
        </li>
      </ul>

      <hr className="services-paywall__line" />

      <div className="services-paywall__subscription">
        <h2 className="services-paywall__subscription__price">
          <span className="services-paywall__subscription__price__mid">90 day free trial</span>
        </h2>

        {
          plan.comparePrice
            ? <span
              className="services-paywall__subscription__compare">Renews at ${plan.comparePrice.toFixed(2)}/{plan.interval} after free trial</span>
            : null}

        <Link to={`/account/trial`}>
          <button type="button" className="btn btn-primary btn-block services-paywall__subscription__button">
            <span>Start trial</span>
            <img src={toAbsoluteUrl("/media/svg/svg-arrow.svg")}
                 className="services-paywall__subscription__button__arrow" />
          </button>
        </Link>
      </div>
    </div>
  );
};
