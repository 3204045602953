import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Layout, LayoutSplashScreen } from "../_metronic/layout";
import BasePage from "./BasePage";
import { AuthPage } from "./modules/Auth";
import * as auth from "./modules/Auth/_redux/authRedux";
import {
  ALLOWED_ROUTES_REDIRECT,
  ALLOWED_ROUTES_REDIRECT_ADMIN,
  ALLOWED_ROUTES_REDIRECT_USER,
  BASE_SITE,
  CreateBaseSiteUrl
} from "./constraints";
import { getURLParam } from "./services/getURLParam";
import Strapi from "./modules/Strapi/Strapi";
import Plan from "../interface/IPlan";
import { redirect } from "./modules/Strapi/Func";
import { User } from "./modules/Strapi/User";

function Routes(props: any) {
  const { connectStrapi, connectUser, savePlans } = props;
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);

  const [strapi, setStrapi] = useState<Strapi | null>(null);
  const [user, setUser] = useState<User | null>(null);

  const [plansLoaded, setPlansLoaded] = useState(false);
  const [urlList, setUrlList] = useState(ALLOWED_ROUTES_REDIRECT);

  const returnTo = getURLParam("returnTo");
  React.useEffect(() => {
    Strapi.connect()
      .then(strapi => setStrapi(strapi));
  }, []);

  React.useEffect(() => {
    if (strapi) {
      User.fetchUser(strapi)
        .then(user => setUser(user));
    }
  }, [strapi]);

  React.useEffect(() => {
    if (strapi && user) {
      // Save strapi in the store
      connectStrapi(strapi);
      connectUser(user);

      if (!user.authenticated) {
        setLoading(false);
        return redirect("/auth/login", { noRedirectFrom: ["/auth"] });
      }

      if (returnTo) {
        window.location.replace(CreateBaseSiteUrl(returnTo));
        return;
      }

      setLoggedIn(true);
      if (user.admin) {
        setUrlList(ALLOWED_ROUTES_REDIRECT_ADMIN);
      } else {
        setUrlList(ALLOWED_ROUTES_REDIRECT_USER);
      }

      // Get and save the plans in the store
      strapi.collection("stripe")
        .getCustom("products")
        .then(products => {
          const p: Record<any, Plan> = {};
          products.forEach((product: Plan) => {
            p[product.intervalText] = product;
          });
          savePlans(p);
          setPlansLoaded(true);
        });

      if (window.location.pathname.startsWith("/auth/") && (user.admin)) {
        window.location.replace("/dashboard");
      }
    } else {
      //setLoading(false);
      setLoggedIn(false);
    }
  }, [user, strapi]);

  React.useEffect(() => {
      if (loading && strapi && plansLoaded) {
        setLoading(false);
      }
    },
    [strapi, plansLoaded]);

  if (loggedIn && returnTo) {
    return <Redirect to={`${BASE_SITE}/${returnTo}`} />;
  }

  if (loading) {
    return <LayoutSplashScreen />;
  }


  let isAllowedRoute = false;
  if (!loggedIn && !window.location.pathname.startsWith("/auth/")) {
    isAllowedRoute = urlList.some(route => window.location.pathname.startsWith(route));
    if (isAllowedRoute) {
      sessionStorage.setItem("nv-redirect_url", window.location.href);
    }
  } else {
    isAllowedRoute = urlList.some(route => window.location.pathname === route);
    if (!isAllowedRoute) {
      isAllowedRoute = urlList
        .filter(route => route.endsWith("*"))
        .map(route => route.substr(0, -1))
        .some(route => window.location.pathname.startsWith(route));
    }

    if (!isAllowedRoute) {
      return <Redirect to="/apps/active" />;
    }
  }

  return (
    <Switch>
      {!loggedIn ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      {!loggedIn ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/registration" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}

export default connect(null, auth.actions)(Routes);
