import Strapi from "./modules/Strapi/Strapi";
import Plan from "../interface/IPlan";
import { User } from "./modules/Strapi/User";
import { SelectItem } from "../components/FormFields/commonTypes";

const {
  REACT_APP_API_URL,
  REACT_APP_BASE_URL,
  REACT_APP_STRIPE_KEY,
  REACT_APP_COOKIE_DOMAIN,
  REACT_APP_COOKIE_SECURE
} = process.env;

export const BASE_SITE = REACT_APP_BASE_URL;

export const API_BASE = REACT_APP_API_URL;

// Set to .therighttool.co.nz on prod
export const COOKIE_DOMAIN = REACT_APP_COOKIE_DOMAIN ?? "";
export const COOKIE_SECURE = REACT_APP_COOKIE_SECURE ?? false;

export const IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp"
];

export const STRIPE_KEY = REACT_APP_STRIPE_KEY ?? "";

interface SpecialOffer extends SelectItem {
  price: number;
  duration: string;
}

export const SPECIAL_OFFERS = [
  {
    id: 30,
    name: "30 days - $9.00",
    price: 9.00,
    duration: "30 days"
  },

  {
    id: 90,
    name: "90 days - $24.00",
    price: 24.00,
    duration: "90 days"
  },

  {
    id: 365,
    name: "365 days - $89.00",
    price: 89.00,
    duration: "365 days"
  }
] as SpecialOffer[];

export const OLD_SPECIAL_OFFERS = {
  30: {
    duration: "30 days",
    price: 9.00
  },

  90: {
    duration: "90 days",
    price: 24.00
  },

  365: {
    duration: "365 days",
    price: 89.00
  }
} as {
  [name: number]: {
    duration: string;
    price: number;
  }
};

export const CreateBaseSiteUrl = (url: string, query?: {
  [name: string]: string | boolean | number,
}) => {
  const queryString =
    query
      ? Object.entries(query)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join("&")
      : "";

  return `${BASE_SITE}${url}${queryString ? `?${queryString}` : ""}`;
};

export enum AppBroadcastChannelEvent {
  CardAdded = "CardAdded",
}

export const AppBroadcastChannel = "BroadcastChannel" in window && new BroadcastChannel("AppBroadcastChannel");

export const ANALYTICS_ENABLED = false;
export const ALLOWED_ROUTES_REDIRECT = [
  "/auth/registration",
  "/auth/login",
  "/auth/forgot-password",
  "/auth/reset-password"
];

export const ALLOWED_ROUTES_REDIRECT_USER = ALLOWED_ROUTES_REDIRECT.concat([
  "/featured",

  "/apps/new",
  "/apps/pending",
  "/apps/pending/view/*",
  "/apps/active",

  "/services/new",
  "/services/pending",
  "/services/active",

  "/account",
  "/account/personal-information",
  "/account/security",
  "/account/payment_methods",
  "/account/subscription"
]);

export const ALLOWED_ROUTES_REDIRECT_ADMIN = ALLOWED_ROUTES_REDIRECT_USER.concat([
  "/dashboard",

  "/apps/active/all",
  "/apps/pending/all",

  "/services/active/all",
  "/services/pending/all",

  "/reviews/apps",
  "/reviews/services",

  "/special-offers/apps",
  "/special-offers/services",

  "/categories",
  "/industries",
  "/locations"
]);

// Usage:
// const user = useSelector(selectAuthUser);
//export const selectAuthUser = async (state: any) => {
//  return await User.fetchUser(await Strapi.connect());
//};

export const selectAuthUser = (state: any) => state.auth.user as User;

// Usage:
// const strapi = useSelector(selectStrapi);
export const selectStrapi = (state: any) => state.auth.strapi as Strapi;

// Usage:
// const plans = useSelector(selectPlans);
export const selectPlans = (state: any) => state.auth.plans as Record<string, Plan>;