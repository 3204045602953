import React, { ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import { IAppListing } from "../../../interface/IAppListing";
import { IServiceListing } from "../../../interface/IServiceListing";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { getListings } from "../../modules/Auth/_redux/authCrud";
import { convertApp, convertService } from "../../modules/Strapi/ApiConverter";

export const SpecialOfferPaywall = (): ReactElement => {
//  useSubheader().setTitle("Paywall");

  const [formOpen, openForm] = useState(false);

  const [selectedListing, setSelectedListing] = useState<string>("");
  const [listing, setListing] = useState<IAppListing | IServiceListing | null>(null);
  const [listingType, setListingType] = useState<string>("");

  const query = "?user=me";

  const [apps, setApps] = useState<IAppListing[]>([]);
  React.useEffect(() => {
    getListings<IAppListing>("apps", convertApp, {})(query).then(setApps);
  }, [query]);

  const [services, setServices] = useState<IServiceListing[]>([]);
  React.useEffect(() => {
    getListings<IServiceListing>("services", convertService, {})(query).then(setServices);
  }, [query]);

  React.useEffect(() => {
    const appInfo = apps.find((listing) => listing.url === selectedListing);
    const serviceInfo = services.find((listing) => listing.url === selectedListing);

    if (appInfo) {
      setListing(appInfo);
      setListingType("apps");
    } else if (serviceInfo) {
      setListing(serviceInfo);
      setListingType("services");
    } else {
      setListing(null);
    }
  }, [selectedListing, apps, services]);

  const listings = [
    ...apps,
    ...services
  ].sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }

    if (a.name > b.name) {
      return 1;
    }

    return 0;
  });

  const getListingId = (listing: IAppListing | IServiceListing) => {
    const splitUrl = listing.url.split("/");
    return splitUrl[splitUrl.length - 1] || splitUrl[splitUrl.length - 2];
  };

  return (
    <>
      <div className="services-paywall">
        <div className="services-paywall__header">
          <img src={toAbsoluteUrl("/media/svg/special-offer.png")} className="services-paywall__header__image" />
          <h1 className="services-paywall__header__title">Add a Special Offer</h1>
          <p className="services-paywall__header__caption">Get more customers by offering things like coupons, special
            extended trials or even pricing specials on your listing.</p>
        </div>

        <span className="services-paywall__subscription__compare font-weight-bold">Starts at $9 per offer</span>

        <button
          type="button"
          onClick={() => openForm(true)}
          className="btn btn-primary btn-block services-paywall__subscription__button">
          <span>Create Offer</span>
          <img src={toAbsoluteUrl("/media/svg/svg-arrow.svg")}
               className="services-paywall__subscription__button__arrow" />
        </button>
      </div>

      <div className={[
        "form-modal",
        formOpen && "form-modal--open"
      ].filter((c) => c).join(" ")}>
        <div className="form-modal__backdrop" onClick={() => openForm(false)}>&nbsp;</div>

        <div className="form-modal__content">
          <div className="card card-custom mb-0">
            <div className="card card-custom">
              {/* begin::Header */}
              <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                  <h4 className="card-label font-weight-bolder text-dark">
                    Special Offer
                  </h4>
                  <span className="text-muted font-weight-bold font-size-sm mt-1">
                    {"Get more customers by offering special offers"}
                  </span>
                </div>

                <div className="card-toolbar">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => openForm(false)}
                  >
                    Close
                  </button>
                </div>
              </div>

              <div className="card-body">
                <div className="tab-content text-center">
                  <div>
                    <span className="card-label font-weight-bolder font-size-lg text-dark">
                      {"Select listing"}
                    </span>
                  </div>

                  <div className="form-group my-3">
                    <select
                      onChange={(el) => {
                        setSelectedListing(el.target.value);
                      }}
                      className={`form-control form-control-lg form-control-solid`}
                      value={selectedListing}
                    >
                      <option defaultChecked disabled={!!selectedListing}>--- Select lisitng ---</option>

                      {
                        listings.map((listing) =>
                          <option key={listing.url} value={listing.url}>{listing.name}</option>
                        )
                      }
                    </select>
                  </div>

                  {listing &&
                    <div className="my-3">
                      <Link
                        to={`/${listingType}/active/view/${
                          getListingId(listing)
                        }/special-offer`}
                        className="btn btn-success"
                      >
                        {"Add special offer"}
                      </Link>
                    </div>}
                </div>
              </div>
              {/* end::Body */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
