import React, { Suspense } from "react";
import { Redirect, Switch, useParams } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import { getListings } from "./modules/Auth/_redux/authCrud";
import { shallowEqual, useSelector } from "react-redux";

import { DashboardView } from "./pages/Dashboard";
import { Featured } from "./pages/Listings/Featured";

import { Apps } from "./pages/Listings/Apps";
import { AppView } from "./pages/Listings/AppView";
import { ServiceView } from "./pages/Listings/ServiceView";
import { UserProfilePage } from "./modules/UserProfile/UserProfilePage";

import { Error404 } from "./pages/404";

import { ANALYTICS_ENABLED, selectAuthUser } from "./constraints";
import { convertApp, convertService } from "./modules/Strapi/ApiConverter";
import ListingCollection from "./pages/Listings/ListingCollection";
import { Category } from "./pages/Metadata/Category";
import { Categories } from "./pages/Metadata/Categories";
import { Industries } from "./pages/Metadata/Industries";
import { Locations } from "./pages/Metadata/Locations";
import { SpecialOffers } from "./pages/SpecialOffers/SpecialOffers";
import { ReviewView } from "./pages/Reviews/ReviewView";
import { Reviews } from "./pages/Reviews/Reviews";
import { ServicesPaywall } from "./pages/Paywall/ServicesPaywall";
import { TrialPaywall } from "./pages/Paywall/TrialPaywall";
import { SpecialOfferPaywall } from "./pages/Paywall/SpecialOfferPaywall";

export default function BasePage(): React.ReactElement {
  const user = useSelector(selectAuthUser, shallowEqual);

  const defaultQuery = "";

  const getApps = React.useMemo(() => getListings("apps", convertApp, {
    legacyPath: "/apps/active"
  }), []);

  const getAllApps = React.useMemo(() => getListings("apps", convertApp, {
    legacyPath: "/apps/active/all",
    all: true
  }), []);

  const getPendingApps = React.useMemo(() => getListings("apps", convertApp, {
    legacyPath: "/pending/apps/",
    pending: true
  }), []);

  const getAllPendingApps = React.useMemo(() => getListings("apps", convertApp, {
    legacyPath: "/pending/apps/",
    pending: true,
    all: true
  }), []);

  const getServices = React.useMemo(() => getListings("services", convertService, {
    legacyPath: "/services/"
  }), []);

  const getPendingServices = React.useMemo(() => getListings("services", convertService, {
    legacyPath: "/pending/services/",
    pending: true
  }), []);

  const getAllServices = React.useMemo(() => getListings("services", convertService, {
    legacyPath: "/services/active/all",
    all: true
  }), []);

  const getAllPendingServices = React.useMemo(() => getListings("services", convertService, {
    legacyPath: "/pending/services/",
    pending: true,
    all: true
  }), []);

  if (!user) {
    return <LayoutSplashScreen />;
  }

  const { listingId } = useParams<{
    listingId?: string;
  }>();

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to={
          (user.admin && ANALYTICS_ENABLED)
            ? "/dashboard"
            : "/apps/active"
        } />

        {/* Dashboard */}
        {user.admin && <ContentRoute path="/dashboard" component={DashboardView} />}

        {/* Featured */}
        {user.admin && <ContentRoute path="/featured" component={Featured} />}

        {/* Account */}
        <ContentRoute path="/account" render={() => <UserProfilePage />} />


        {/* Paywall */}
        {!user.upgraded && <ContentRoute path="/paywall/services" component={ServicesPaywall} />}
        {!user.upgraded && <ContentRoute path="/paywall/trial" component={TrialPaywall} />}
        {!user.upgraded && <ContentRoute path="/paywall/special-offer" component={SpecialOfferPaywall} />}


        {/* App Listings */}
        <ContentRoute path={"/apps/*"} render={() => {
          return (
            <ListingCollection
              listingId={listingId}
              collectionType={"apps"}
              listingListPage={Apps}
              listingDetailPage={AppView}
              defaultQuery={window.location.search || defaultQuery}
              allPendingFn={getAllPendingApps}
              pendingFn={getPendingApps}
              allActiveFn={getAllApps}
              activeFn={getApps}
            />
          );
        }} />


        {/* Service Listings */}
        <ContentRoute path={"/services/*"} render={() => {
          return (
            <ListingCollection
              listingId={listingId}
              collectionType={"services"}
              listingListPage={Apps}
              listingDetailPage={ServiceView}
              defaultQuery={window.location.search || defaultQuery}
              allPendingFn={getAllPendingServices}
              pendingFn={getPendingServices}
              allActiveFn={getAllServices}
              activeFn={getServices}
            />
          );
        }} />


        {/* App Reviews */}
        <ContentRoute path="/reviews/apps/new" render={
          () =>
            <ReviewView
              key="/reviews/apps/new"
              baseName="/apps/"
              isPending={false} />
        } />
        <ContentRoute path="/reviews/apps/pending/view/:reviewId" render={
          (props: any) =>
            <ReviewView
              key="/reviews/apps/pending/view"
              baseName="/apps/"
              reviewId={props.match.params.reviewId}
              isPending={true} />
        } />
        <ContentRoute path="/reviews/apps/pending" render={
          () =>
            <Reviews
              key="/reviews/apps/pending"
              title="Pending Reviews"
              caption="Reviews requiring approval"
              baseName="/apps/"
              isPending={true} />
        } />
        <ContentRoute path="/reviews/apps/active/view/:reviewId" render={
          (props: any) =>
            <ReviewView
              key="/reviews/apps/active/view"
              baseName="/apps/"
              reviewId={props.match.params.reviewId}
              isPending={false} />
        } />
        <ContentRoute path="/reviews/apps/active" render={
          () =>
            <Reviews
              key="/reviews/apps/active"
              title="Reviews"
              caption="Reviews posted on The Right Tool"
              baseName="/apps/"
              isPending={false} />
        } />

        {/* Service Reviews */}
        <ContentRoute path="/reviews/services/new" render={
          () =>
            <ReviewView
              key="/reviews/services/new"
              baseName="/services/"
              isPending={false} />
        } />
        <ContentRoute path="/reviews/services/pending/view/:reviewId" render={
          (props: any) =>
            <ReviewView
              key="/reviews/services/pending/view"
              baseName="/services/"
              reviewId={props.match.params.reviewId}
              isPending={true} />
        } />
        <ContentRoute path="/reviews/services/pending" render={
          () =>
            <Reviews
              key="/reviews/services/pending"
              title="Pending Reviews"
              caption="Reviews requiring approval"
              baseName="/services/"
              isPending={true} />
        } />
        <ContentRoute path="/reviews/services/active/view/:reviewId" render={
          (props: any) =>
            <ReviewView
              key="/reviews/services/active/view"
              baseName="/services/"
              reviewId={props.match.params.reviewId}
              isPending={false} />
        } />
        <ContentRoute path="/reviews/services/active" render={
          () =>
            <Reviews
              key="/reviews/services/active"
              title="Reviews"
              caption="Reviews posted on The Right Tool"
              baseName="/services/"
              isPending={false} />
        } />

        {/* Apps Special Offers */}
        <ContentRoute path="/special-offers/apps/pending" render={
          () =>
            <SpecialOffers
              key="/special-offers/apps/pending"
              title="Pending Special Offers"
              caption="Special Offers requiring approval"
              baseName="/apps/"
              isPending={true} />
        } />
        <ContentRoute path="/special-offers/apps/active" render={
          () =>
            <SpecialOffers
              key="/special-offers/apps/active"
              title="Special Offers"
              caption="Special Offers"
              baseName="/apps/"
              isPending={false} />
        } />

        {/* Services Special Offers */}
        <ContentRoute path="/special-offers/services/pending" render={
          () =>
            <SpecialOffers
              key="/special-offers/services/pending"
              title="Pending Special Offers"
              caption="Special Offers requiring approval"
              baseName="/services/"
              isPending={true} />
        } />
        <ContentRoute path="/special-offers/services/active" render={
          () =>
            <SpecialOffers
              key="/special-offers/services/active"
              title="Special Offers"
              caption="Special Offers"
              baseName="/services/"
              isPending={false} />
        } />

        {/* Metadata */}
        <ContentRoute path="/categories/view/:categoryId" render={
          (props: any) =>
            <Category
              categoryId={props.match.params.categoryId} />
        } />
        <ContentRoute path="/categories" render={
          () =>
            <Categories />
        } />
        <ContentRoute path="/industries" render={
          () =>
            <Industries />
        } />
        <ContentRoute path="/locations" render={
          () =>
            <Locations />
        } />

        {/* 404 */}
        <ContentRoute component={Error404} />
      </Switch>
    </Suspense>
  );
}
