import Strapi from "./Strapi";
import { StrapiComplexFilter, StrapiFilter, StrapiRecordFilters } from "./Types";
import qs from "qs";
import { StrapiMetaResponse, StrapiResponse } from "./types/StrapiResponses";
import { ConcurrentPromiseQueue } from "concurrent-promise-queue";
import { StrapiPostRecord } from "./Func";

export class Collection {
  public fields = [];
  private readonly strapi: Strapi;
  private filters: StrapiRecordFilters & StrapiComplexFilter = {};
  private locale = ["en"];
  private sort = ["id:asc"];
  private pagination = {
    page: 1,
    pageSize: 10
  };
  private name: string;
  private _publicationState = "live";

  public constructor(collectionName: string, strapi: Strapi) {
    this.strapi = strapi;
    this.name = collectionName;
  }

  private _meta ?: StrapiMetaResponse;

  get meta() {
    return this._meta?.pagination ?? { page: 0, pageCount: 0, pageSize: 10, total: 0 };
  }

  public addFilter(key: string, filter: StrapiFilter | StrapiRecordFilters) {
    this.filters[key] = filter;
    return this;
  }

  public addComplexFilter(filter: StrapiComplexFilter) {
    if (filter.$and) {
      this.filters["$and"] = filter.$and;
    }

    if (filter.$or) {
      this.filters["$or"] = filter.$or;
    }

    if (filter.$not) {
      this.filters["$not"] = filter.$not;
    }

  }

  public deleteFilter(key: string) {
    if (key in this.filters) {
      delete this.filters[key];
    }

    return this;
  }

  public sortAsc(fieldName: string, clear = false) {
    if (clear) {
      this.sort = [];
    }

    this.sort.push(`${fieldName}:asc`);
    return this;
  }

  public sortDesc(fieldName: string, clear = false) {
    if (clear) {
      this.sort = [];
    }

    this.sort.push(`${fieldName}:desc`);
    return this;
  }

  public async getAll<T = Record<string, any>>() {
    const currentPagination = { ...this.pagination };
    this.resetPagination();

    return this.get()
      .then(resultSet => {
        const queue = new ConcurrentPromiseQueue({ maxNumberOfConcurrentPromises: 2 });
        if (this._meta) {
          const { page, pageCount } = this._meta.pagination;

          const promises = [
            queue.addPromise(() => new Promise((resolve) => resolve(resultSet)))
          ] as Promise<T>[];

          for (let currentPage = page + 1; currentPage <= pageCount; currentPage++) {
            promises.push(queue.addPromise(() => this.getPage<T>(currentPage)) as Promise<T>);
          }

          return Promise.all(promises);
        }

        return null;
      })
      .then((promiseAllResults) => {
        if (promiseAllResults) {
          return promiseAllResults.reduce((result, data) => result.concat(data), [] as T[]);
        }
      })
      .finally(() => {
        this.pagination = { ...currentPagination };
      });
  }

  public async getPage<T = Record<string, any>>(pageNumber: number) {
    if (pageNumber < 0) {
      throw new Error("Invalid page number");
    }

    this.pagination.page = pageNumber;
    return this.get<T>();
  }

  public async getCustom<T = Record<string, any>>(endpoint: string) {
    return await this._get<T>(`/api/${this.name}/${endpoint}`);
  }

  public async get<T = Record<string, any>>() {
    return await this._get<T>(`/api/${this.name}?${this.buildQuery()}`);
  }

  public async getOne<T = Record<string, any>>() {
    return await this.get<T>()
      .then(data => {
        if (data.length === 1) {
          return data[0] as StrapiResponse<T>;
        }
      });
  }

  public showDrafts(showDrafts = true) {
    if (showDrafts) {
      this._publicationState = "preview";
    } else {
      this._publicationState = "live";
    }
  }

  public async updateRecord<T = Record<string, any>>(id: number, data: T) {
    return await this.strapi.axios_connection.put(`/api/${this.name}/${id}`, { data })
      .then(response => {
        if (response.status === 200) {
          return response.data as T;
        }
        throw new Error("Error updating Strapi record");
      });
  }

  public async post(data: StrapiPostRecord | FormData) {
    return this.strapi.axios_connection.post(`/api/${this.name}`, { data });
  }

  public async patch(recordId: number, data: StrapiPostRecord | FormData) {
    return this.strapi.axios_connection.patch(`/api/${this.name}/${recordId}`, { data });
  }

  public async put(recordId: number, data: StrapiPostRecord | FormData) {
    return this.strapi.axios_connection.put(`/api/${this.name}/${recordId}`, { data });
  }

  public async delete(recordId: number) {
    return this.strapi.connection.delete(`/${this.name}/${recordId}`);
  }

  private resetPagination() {
    this.pagination = {
      page: 1,
      pageSize: 10
    };
  }

  private async _get<T = Record<string, any>>(url: string) {
    return await this.strapi.axios_connection.get(url)
      .then(response => response.status === 200 ? response.data : null)
      .then(response => {
        if (response) {
          if (response.meta) {
            this._meta = response.meta;
          } else {
            this._meta = undefined;
          }

          if (response.data) {
            return response.data as T[];
          }

          if (response && Array.isArray(response)) {
            return response as T[];
          }

          return response;
        }
        return [] as StrapiResponse<T>[];
      });
  }

  private buildQuery() {
    const queryObject = {
      sort: Object.assign([], this.sort),
      filters: Object.assign({}, this.filters),
      pagination: Object.assign({}, this.pagination),
      locale: Object.assign([], this.locale),
      publicationState: this._publicationState,
      populate: ["deep,2"]
    };

    return qs.stringify(queryObject, { encodeValuesOnly: true });
  }
}