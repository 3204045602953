import { Collection } from "./Collection";
import Store from "../../services/Store";
import Cookies from "../../services/Cookies";
import { Upload } from "./Upload";
import { User } from "./User";
import wretch from "wretch";
import axios, { AxiosInstance } from "axios";
import { StrapiBase } from "./StrapiBase";
import { redirect } from "./Func";

export default class Strapi extends StrapiBase {
  private static instance: Strapi;
  private collections = {} as Record<any, Collection>;
  private _userClass: User;

  public constructor() {
    super();
    this._axios_connection = this.initialiseConnection();
    this._userClass = new User(this);
  }

  private _upload: Upload | null = null;

  get upload(): Upload {
    if (!this._upload) {
      this._upload = new Upload(this);
    }

    return this._upload;
  }

  private _axios_connection: AxiosInstance;

  public get axios_connection() {
    this._axios_connection.defaults.headers.common["Authorization"] = Store.get("AUTH_TOKEN");

    return this._axios_connection;
  }

  public get connection() {
    return Strapi.createConnection();
  }

  private _authenticated = false;

  get authenticated() {
    return this._authenticated;
  }

  public static async connect() {
    if (!this.instance) {
      this.instance = new Strapi();
    }

    return this.instance;
  }

  private static createConnection(headers = { "Content-Type": "application/json" }) {
    const { REACT_APP_STRAPI_URL } = process.env;

    if (!REACT_APP_STRAPI_URL) {
      throw new Error("Connection authentication error");
    }

    let url = REACT_APP_STRAPI_URL;
    if (!url.endsWith("api") && !url.endsWith("api/")) {
      if (url.endsWith("api/")) {
        url = url.substring(0, url.length - 1);
      } else {
        url += "/api";
      }
    }

    let conn = wretch(url)
      .headers(headers)
      .resolve((_) => _.forbidden((err) => {
        Cookies.remove("AUTH_TOKEN");
        Store.clear();
        redirect("/auth/login", {
          errorMessage: "User is not authenticated",
          errorObject: err
        });
      }));

    const token = Store.get("AUTH_TOKEN");
    if (token) {
      conn = conn.auth(token);
    }

    return conn;
  }

  public collection(collectionName: string) {
    if (this.collections[collectionName]) return this.collections[collectionName];

    const collection = new Collection(collectionName, this);
    if (collection) {
      this.collections[collectionName] = collection;
      return collection;
    }

    throw new Error("Invalid collection");
  }

  public async forgotPasswordRequest(email: string) {
    if (!email) {
      throw new Error("Email not provided");
    }

    return await this.axios_connection.post(
      "/api/auth/forgot-password", { email }
    )
      .then(response => {
        if (response.status === 200) {
          return response.data;
        }

        throw new Error("An error occurred while sending password reset email");
      })
      .catch(err => {
        throw new Error(err.message);
      });
  }

  public async resetPassword(code: string, newPassword: string, newPasswordConfirm: string) {
    if (!code || !newPassword) {
      throw new Error("Code or new password is not provided");
    }

    return await this.axios_connection
      .post("/api/auth/reset-password", {
        code,
        password: newPassword,
        passwordConfirmation: newPasswordConfirm
      })
      .then(response => {
        if (response.status === 200 && response.data) {
          // Login user
          const { jwt } = response.data;
          this.saveToken(`Bearer ${jwt}`);
          return User.fetchUser(this)
            .then(user => {
              user.reload();
              return user;
            });
        }

        throw new Error("Password reset failed");
      })
      .catch(error => {
        if (error && error.response && error.response.data && error.response.data.error) {
          const err = error.response.data.error;
          if (err.message === "Incorrect code provided") {
            setTimeout(() => {
              redirect("/auth/forgot-password", {
                errorMessage: "Incorrect code provided"
              });
            }, 5000);

            throw new Error("Reset link timed out, please request another reset link");
          }
        }
        throw new Error(error.message);
      });
  }

  private initialiseConnection() {
    const { REACT_APP_STRAPI_URL } = process.env;

    return axios.create({
      headers: { "Content-Type": "application/json" },
      baseURL: REACT_APP_STRAPI_URL
    });
  }

  private saveToken(token: string | null) {
    if (!token) {
      delete (this._axios_connection.defaults.headers.common["Authorization"]);
      // Store.remove("v705-auth");
      Store.remove("AUTH_TOKEN");
      Cookies.remove("AUTH_TOKEN");
    } else {
      this._axios_connection.defaults.headers.common["Authorization"] = token;
      // Store.set("v705-auth", token);
      Store.set("AUTH_TOKEN", token, 7);
      Cookies.set("AUTH_TOKEN", token, 7);
    }

  }

//  private async authenticate(username?: string, password?: string) {
//    const { REACT_APP_STRAPI_USER, REACT_APP_STRAPI_PASS } = process.env;
//
//    const user = username || REACT_APP_STRAPI_USER;
//    const pass = password || REACT_APP_STRAPI_PASS;
//
//    if (!user || !pass) {
//      throw new Error("Username or pass not provided");
//    }
//
//    return await this.axios_connection
//      .post("/api/auth/local", {
//        identifier: user,
//        password: pass
//      })
//      .then(response => {
//        if (response && response.status === 200) {
//          return response.data;
//        }
//
//        throw new Error("Authentication failed");
//      })
//      .then(data => {
//        if (data && data.jwt && data.user) {
//          this.saveToken(`Bearer ${data.jwt}`);
//          const user = data.user as StrapiUserResponse;
//          this.storeUser(user);
//          return this.fetchAccountDetails();
//        }
//
//        throw new Error("Jwt failed");
//      })
//      .then(state => state || false);
//  }
}
