import { StrapiAuthenticatedUser } from "./types/StrapiResponses";
import CryptoJS from "crypto-js";
import Store from "../../services/Store";

const ENCRYPTION_KEY = process.env.REACT_APP_STRIPE_KEY ?? "";

function encryptData(data: string): string {
  return CryptoJS.AES.encrypt(data, ENCRYPTION_KEY).toString();
}

function decryptData(data: string): string | null {
  try {
    const bytes = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (e) {
    return null;
  }

}

export class UserCache {
  public static getUserFromCache(): StrapiAuthenticatedUser | null {
    const encryptedUser = Store.get("user");
    const userStamp = Store.get("user_stamp");
    if (!encryptedUser || !userStamp) return null;

    try {
      const userJson = decryptData(encryptedUser);

      if (!userJson) return null;

      const FIVE_MINUTES_IN_MS = 5 * 60 * 1000;
      const userStampDate = new Date(userStamp);
      const currentTime = new Date();

      if (currentTime.getTime() - userStampDate.getTime() > FIVE_MINUTES_IN_MS) {
        return null;
      }

      return JSON.parse(userJson) as StrapiAuthenticatedUser;
    } catch (e) {
      console.error("Error decrypting user data", e);
      return null;
    }
  }

  public static setUserInCache(user: StrapiAuthenticatedUser): void {
    const userJson = JSON.stringify(user);
    const encryptedUser = encryptData(userJson);
    Store.set("user", encryptedUser, 1);
    Store.set("user_stamp", new Date().toISOString(), 1);
  }

  public static removeUserFromCache(): void {
    Store.remove("user");
    Store.remove("user_stamp");
  }
}