import { SelectItem, SelectProps } from "./commonTypes";
import Select from "react-select";
import React from "react";

export default function SingleDropSelect<T extends SelectItem>(props: SelectProps<T>) {
  const getOptions = () => {
    return props.items.map((item) => ({
      label: item.name,
      value: item.id.toString()
    }));
  };

  const getSelectedOptions = () => {
    if (props.selectedItem && props.selectedItem.id) {
      return {
        label: props.selectedItem.name,
        value: props.selectedItem.id.toString()
      };
    }

    return undefined;
  };

  return (
    <div className="form-group row">
      <label className="col-xl-3 col-lg-3 col-form-label">
        {props.label}
        <br />
        <em className="text-muted">Max 1</em>
      </label>
      <div className="col-lg-9">
        <Select
          className="basic-single mb-0"
          classNamePrefix="select"
          isLoading={props.items.length === 0}
          isClearable={false}
          isSearchable={true}
          isMulti={false}
          closeMenuOnSelect={true}
          hideSelectedOptions={false}
          options={getOptions()}
          value={getSelectedOptions()}
          onChange={(values) => {
            if (values) {
              const selected = props.items.filter(item => item.id === parseInt(values.value));
              if (selected && selected.length === 1) {
                return props.setSelectedItem(selected[0]);
              }
            }

            props.setSelectedItem({ id: 0 } as T);
          }}
          noOptionsMessage={() => "No results found"}
        />

        {props.items && props.items.map((item, index) => {
          return (
            <input
              key={`${props.label.toLowerCase()}-${item.id}-${index}`}
              className="d-none"
              type="checkbox"
              name={props.id}
              value={item.id}
              checked={(props.selectedItem && props.selectedItem.id === item.id)}
              onChange={() => {
                // null
              }}
            />
          );
        })}

        {props.formik.touched[props.id] && props.formik.errors[props.id] ? (
          <div className="invalid-feedback">
            {props.formik.errors[props.id]}
          </div>
        ) : null}
      </div>
    </div>
  );
}