import React from "react";
import { SelectItem, SelectProps } from "./commonTypes";

export default function RadioSelect<T extends SelectItem>(props: SelectProps<T>) {
  return (
    <div className="form-group row">
      <label className="col-xl-3 col-lg-3 col-form-label">
        {props.label}
        <br />
        <em className="text-muted">Max 1</em>
      </label>
      <div className="col-lg-9">
        {props.items && props.items.map((item, i) => (
          <div key={`${props.label.toLowerCase()}-${item.url}-${i}`}>
            <label className={`d-flex align-items-center ${props.items.length === (i + 1) ? "mb-0" : ""}`}>
              <label className="checkbox checkbox-lg checkbox-circle flex-shrink-0 m-0 mr-4">
                <input
                  type="radio"
                  name="category"
                  value={item.id}
                  onChange={() => props.setSelectedItem(item)}
                  checked={props.selectedItem && item.id === props.selectedItem.id}
                />
                <span></span>
              </label>
              <div className="d-flex flex-column flex-grow-1">
                <span>{item.name}</span>
              </div>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}