import { isArray } from "lodash";
import dotenv from "dotenv";

export abstract class StrapiBase {
  protected constructor() {
    dotenv.config();
  }

  public static flattenFirstStrapiObj<T = Record<string, any>>(obj: any): T {
    const holding = this.flattenStrapiObj(obj);
    if (Array.isArray(holding)) {
      return holding[0] as T;
    }

    return holding as T;
  }

  public static flattenStrapiObj(obj: any): Record<string, any> | Record<string, any>[] {
    if (obj.id && obj.attributes) {
      return { id: obj.id, contentType: obj.contentType, ...obj.attributes } as Record<string, any>;
    }

    if (obj.data) {
      // There is a data sub object, could be a single item, or array of items (or empty)
      if (Array.isArray(obj.data)) {
        // It's an array of items,
        if (obj.data.length > 0) {
          return obj.data.map((dataObj: any) => this.flattenStrapiObj(dataObj)) as Record<string, any>[];
        }

        // If there are no items we should return a "blank" object
        return obj.data as Record<string, any>;
      }

      // Is this a single item?
      if ("id" in obj.data && "attributes" in obj.data) {
        return { id: obj.data.id, contentType: obj.data.contentType, ...obj.data.attributes } as Record<string, any>;
      }
    }

    // Are we the array?
    if (isArray(obj) && obj.length > 0) {
      return obj.map(item => this.flattenStrapiObj(item)) as Record<string, any>[];
    }

    // Probably nothing to do - fallback
    return obj as Record<string, any>;
  }
}