// NB: This is a ts original of cookie management, if any changes are made here
// please copy them to the website front end and vice versa
import { COOKIE_DOMAIN, COOKIE_SECURE } from "../constraints";
import Store from "./Store";

export const setCookie = (key: string, value: string, exdays: number) => {
  const d = new Date();
  d.setTime((d.getTime() + (exdays * 24 * 60 * 60 * 1000)));

  let cookieString = `${key}=${value};`;
  if (COOKIE_DOMAIN) {
    cookieString += `domain=${COOKIE_DOMAIN};`;
  }

  cookieString += "path=/;";

  if (COOKIE_DOMAIN && COOKIE_SECURE) {
    cookieString += "secure=true;";
  }

  document.cookie = `${cookieString};expires=${d.toUTCString()}`;

  // Alternative to cookie, used in backup
  Store.set(key, value, exdays);
};

export const getCookie = (key: string) => {
  const name = key + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }

  // Alternative to cookie, used in backup
  return Store.get(key) ?? "";
};

export const removeCookie = (key: string) => setCookie(key, "", -1);

export default {
  set: setCookie,
  get: getCookie,
  remove: removeCookie
};
