import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as auth from "../Auth";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import { selectAuthUser, selectStrapi } from "../../constraints";

const PersonaInformation = (props: any) => {
  const { intl } = props;

  // Fields
  const strapi = useSelector(selectStrapi);
  const user = useSelector(selectAuthUser);

  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // UI Helpers
  const initialValues = {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email
  };
  const Schema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required")
  });

  const getInputClasses = (fieldname: keyof typeof initialValues) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      saveUser(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    }
  });

  // Methods
  const saveUser = (values: any, setStatus: (status: any) => void, setSubmitting: (setTo: boolean) => void) => {
    setLoading(true);
    setIsSuccess(false);
    if (user && user.account) {
      strapi.collection("accounts")
        .updateRecord(user.account, values)
        .then(result => {
          if (result) {
            setIsSuccess(true);
            user.reload();
            formik.resetForm();
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
            setStatus(
              intl.formatMessage({
                id: "AUTH.REGISTER.FAILED"
              })
            );

            if (err.response.data.error.message === "This attribute must be unique") {
              formik.setFieldError("email", "Email address already in use");
            }
          }
        })
        .finally(() => {
          setSubmitting(false);
          setLoading(false);
        });
    }
  };

  return (
    <form
      className="card card-custom"
      onSubmit={formik.handleSubmit}
    >
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Personal Information
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            View your personal information
          </span>
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-success mr-2"
            disabled={
              formik.isSubmitting || (formik.touched && !formik.isValid)
            }
          >
            Save Changes
            {formik.isSubmitting}
          </button>
          <Link
            to="/account/"
            className="btn btn-secondary"
          >
            Cancel
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        {/* begin::Body */}
        <div className="card-body">
          {/* begin::Alert */}
          {isSuccess && (
            <div
              className="alert alert-custom alert-light-success fade show mb-10"
              role="alert"
            >
              <div className="alert-text font-weight-bold">Account settings updated.</div>
              <div className="alert-close" onClick={() => setIsSuccess(false)}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <i className="ki ki-close"></i>
                  </span>
                </button>
              </div>
            </div>
          )}
          {/* end::Alert */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              First Name
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder="First name"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "first_name"
                )}`}
                {...formik.getFieldProps("first_name")}
              />
              {formik.touched.first_name && formik.errors.first_name ? (
                <div className="invalid-feedback">
                  {formik.errors.first_name as string}
                </div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Last Name
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder="Last name"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "last_name"
                )}`}
                {...formik.getFieldProps("last_name")}
              />
              {formik.touched.last_name && formik.errors.last_name ? (
                <div className="invalid-feedback">{formik.errors.last_name as string}</div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Email
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="email"
                placeholder="Email"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "email"
                )}`}
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="invalid-feedback">{formik.errors.email as string}</div>
              ) : null}
            </div>
          </div>
        </div>
        {/* end::Body */}
      </div>
      {/* end::Form */}
    </form>
  );
};

export default injectIntl(connect(null, auth.actions)(PersonaInformation));
