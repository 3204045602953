/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkIsActive } from "../../../../_helpers";
import {
  CreateBaseSiteUrl, selectAuthUser,
  selectStrapi
} from "../../../../../app/constraints";

const filterMenuItems = (menu, isAdmin) => {
  return menu.filter(
    section => (isAdmin && section.isAdmin) || !section.isAdmin).
    map(section => ({
      ...section,
      items: section.items.filter(
        item => (isAdmin && item.isAdmin) || !item.isAdmin)
    })).
    filter(section => section.items.length > 0);
};

export function AsideMenuList ({ layoutProps }) {
  const user = useSelector(selectAuthUser);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (user && user.admin) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [user]);

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const menu = [
    {
      label: "Dashboard",
      isAdmin: true,
      items: [
        {
          label: "Dashboard",
          url: "/dashboard",
          hasSubmenu: false,
          isAdmin: true
        },
        {
          label: "Featured",
          url: "/featured",
          hasSubmenu: false,
          isAdmin: true,
          badge: "NEW"
        }
      ]
    },

    {
      label: "App Listings",
      isAdmin: false,
      items: [
        {
          label: "All listings",
          url: "/apps/active/all",
          isAdmin: true,
          hasSubmenu: false
        },
        {
          label: "My listings",
          url: "/apps/active",
          isAdmin: false,
          hasSubmenu: false
        },
        {
          label: "Pending listings",
          url: `/apps/pending${(isAdmin) ? "/all" : ""}`,
          isAdmin: false,
          hasSubmenu: false
        }
      ]
    },

    {
      label: "Cloud Experts",
      isAdmin: false,
      items: [
        {
          label: "All listings",
          url: "/services/active/all",
          isAdmin: true,
          hasSubmenu: false
        },
        {
          label: "My listings",
          url: "/services/active",
          isAdmin: false,
          hasSubmenu: false
        },
        {
          label: "Pending listings",
          url: `/services/pending${(isAdmin) ? "/all" : ""}`,
          isAdmin: false,
          hasSubmenu: false
        }
      ]
    },

    {
      label: "Reviews",
      isAdmin: true,
      items: [
        {
          label: "App reviews",
          url: "/reviews/apps",
          isAdmin: true,
          hasSubmenu: false
        },

        {
          label: "Service reviews",
          url: "/reviews/services",
          isAdmin: true,
          hasSubmenu: false
        }
      ]
    },

    {
      label: "Special Offers",
      isAdmin: true,
      items: [
        {
          label: "App Offers",
          url: "/special-offers/apps",
          isAdmin: true,
          hasSubmenu: false
        },

        {
          label: "Service Offers",
          url: "/special-offers/services",
          isAdmin: true,
          hasSubmenu: false
        }
      ]
    },

    {
      label: "Metadata",
      isAdmin: true,
      items: [
        {
          label: "Categories",
          url: "/categories",
          isAdmin: true,
          hasSubmenu: false
        },

        {
          label: "Industries",
          url: "/industries",
          isAdmin: true,
          hasSubmenu: false
        },

        {
          label: "Locations",
          url: "/locations",
          isAdmin: true,
          hasSubmenu: false
        }
      ]
    },

    {
      label: "Account",
      mobileOnly: true,
      isAdmin: false,
      items: [
        {
          label: "My account",
          url: "/account",
          mobileOnly: true,
          isAdmin: false,
          hasSubmenu: false
        },

        {
          label: "Sign out",
          url: "/logout",
          mobileOnly: true,
          isAdmin: false,
          hasSubmenu: false
        }
      ]
    }
  ];

  const filteredMenu = useMemo(() => filterMenuItems(menu, isAdmin), [menu]);

  const renderSection = (section, key) => {
    const sectionClass = `${section.mobileOnly
      ? "d-flex d-lg-none "
      : ""}menu-section`;
    return (
      <React.Fragment key={key}>
        <li className={sectionClass}>
          <h4 className="menu-text">{section.label}</h4>
        </li>
        {section.items.map(
          (item, itemKey) => renderSectionItem(item, `${key}${itemKey}`))}
      </React.Fragment>
    );
  };

  const renderSectionItem = (item, key) => {
    const itemClass = `${item.mobileOnly
      ? "d-flex d-lg-none "
      : ""}menu-item${getMenuItemActive(item.url, item.hasSubmenu)}`;
    return (
      <li key={key} className={itemClass}
          aria-haspopup="true">
        <NavLink className="menu-link" to={item.url}>
                    <span className="menu-text">
                      {item.label}
                      {item.badge && <span
                        className="badge badge-secondary ml-2">{item.badge}</span>}
                    </span>
        </NavLink>
      </li>
    );
  };

  const renderMenu = () => {
    return filteredMenu.map((section, si) => renderSection(section, si));
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className="menu-item"
          aria-haspopup="true"
        >
          <a className="menu-link" href={CreateBaseSiteUrl("/")}>
            <span className="menu-text">Back to The Right Tool</span>
            <span className="icon"></span>
          </a>
        </li>

        {renderMenu()}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
