import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link, Redirect } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Loading from "../../Loading";

import { IAppListing } from "../../../interface/IAppListing";
import { IIndustry } from "../../../interface/IIndustry";
import { ISubcategory } from "../../../interface/ISubcategory";
import { ICategory } from "../../../interface/ICategory";
import { commonGetInputClasses, CommonView, handleSubmit } from "./CommonView";
import { selectAuthUser, selectStrapi } from "../../constraints";
import {
  StrapiAppResponse,
  StrapiCategoryResponse,
  StrapiIndustryResponse,
  StrapiSubcategoryResponse
} from "../../modules/Strapi/types/StrapiResponses";
import { convertRelation } from "../../modules/Strapi/Func";
import { convertApp, convertCategory, convertSubcategory } from "../../modules/Strapi/ApiConverter";

import {
  ConvertedCategoryResponse,
  ConvertedMiniResponse,
  ConvertedSubcategoryResponse
} from "../../modules/Strapi/types/ConvertedTypes";
import {
  FileInput,
  MultiDropSelect,
  RadioSelect,
  SingleDropSelect,
  TextArea,
  TextField
} from "../../../components/FormFields";

import FormAlertMessage, { FormAlertMessageProps } from "../../../components/FormMessages/FormAlertMessage";
import FormDeclinedMessage from "../../../components/FormMessages/FormDeclinedMessage";

export const AppView = (props: any) => {
  const user = useSelector(selectAuthUser, shallowEqual);
  const strapi = useSelector(selectStrapi);

  // useSubheader().setTitle("Listing");

  const [query] = useState(window.location.search);

  const [isLoaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const [listing, setListing] = useState<IAppListing>({} as any);

  const [message, setMessage] = useState<FormAlertMessageProps>({ type: "info", message: "" });
  const [redirectTo, redirect] = useState("");
  const [nzOwned, setNzOwned] = useState<boolean>(false);

  const [listingStatus, setListingStatus] = useState("PENDING");

  const { initialValues, Schema } = CommonView("apps");

  type StrapiData = {
    industries: IIndustry[];
    selectedIndustry: IIndustry;

    categories: ICategory[];
    selectedCategory: ICategory

    subcategories: ISubcategory[];
    filteredSubcategories: ISubcategory[];
    selectedSubcategories: ISubcategory[];
  }

  const defaultIndustry: IIndustry = {
    id: 0,
    url: "",
    name: ""
  };

  const defaultCategory: ICategory = {
    id: 0,
    url: "",
    name: "",
    subcategories: []
  };

  const [strapiData, setStrapiData] = useState<StrapiData>({
    industries: [] as IIndustry[],
    selectedIndustry: defaultIndustry,
    categories: [] as ICategory[],
    selectedCategory: defaultCategory,
    subcategories: [] as ISubcategory[],
    filteredSubcategories: [] as ISubcategory[],
    selectedSubcategories: [] as ISubcategory[]
  });
  const updateStrapiData = (data: Partial<StrapiData>) => {
    setStrapiData({
      ...strapiData,
      ...data
    });
  };

  const fetchData = async () => {
    const convert = {
      industries: (value?: StrapiIndustryResponse[]) => convertRelation({ data: value }, "industries"),
      categories: (value?: StrapiCategoryResponse[]) => (value ?? []).map(convertCategory),
      subcategories: (value?: StrapiSubcategoryResponse[]) => (value ?? []).map(convertSubcategory)
    };

    const industries = strapi.collection("industries")
      .getAll<StrapiIndustryResponse>()
      .then(convert.industries);

    const categories = strapi.collection("categories")
      .getAll<StrapiCategoryResponse>()
      .then(convert.categories);

    const subcategories = strapi.collection("subcategories")
      .getAll<StrapiSubcategoryResponse>()
      .then(convert.subcategories);

    await Promise.all([industries, categories, subcategories])
      .then(([industries, categories, subcategories]) => {
        setStrapiData({
          ...strapiData,
          industries,
          categories,
          subcategories
        });

        if (!isNew) {
          return strapi.collection("app")
            .getCustom<StrapiAppResponse>(listingId) //NB: Listing id in this case is the slug
            .then(convertApp)
            .then(app => {
              let selectedIndustry: ConvertedMiniResponse | undefined;
              let selectedCategory: ConvertedCategoryResponse | undefined;
              let selectedSubcategories: ConvertedSubcategoryResponse[] = [];

              if (app.industries && app.industries.length > 0) {
                selectedIndustry = industries.find(i => app.industries[0].id === i.id);
              }

              if (app.subcategories && app.subcategories.length > 0) {
                // Try to find the category and subcategory
                selectedSubcategories = app.subcategories
                  .map((sub: any) => subcategories.find(sc => sc.id === sub.id))
                  .filter(sc => sc) as ConvertedSubcategoryResponse[];


                if (selectedSubcategories && selectedSubcategories.length > 0) {
                  selectedCategory = categories.find(c => c.id === selectedSubcategories[0].category_id);
                }
              }

              const newValues = {
                ...app,
                industries: [selectedIndustry?.id ?? 0],
                subcategories: selectedSubcategories.map(sc => sc.id),
                admin_note: "" //app.admin_note || ""
              };

              formik.setValues(newValues as any);
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setListing(newValues);

              setNzOwned(app.nz_owned);
              setListingStatus(app.status as string);
              setLoading(false);

              updateStrapiData({
                industries,
                categories,
                subcategories,
                selectedIndustry: selectedIndustry,
                selectedCategory: selectedCategory,
                selectedSubcategories: selectedSubcategories
              });
            });

        }
      });
  };

  useEffect(() => {
    if (strapiData.selectedCategory && strapiData.selectedCategory.id !== 0) {
      const filtered = strapiData.subcategories.filter(subcategory => subcategory.category_id === strapiData.selectedCategory.id);
      const filteredIds = filtered.map(filter => filter.id);

      updateStrapiData({
        filteredSubcategories: filtered,
        selectedSubcategories: strapiData.selectedSubcategories.filter(selected => filteredIds.includes(selected.id))
      });
    }
  }, [strapiData.selectedCategory]);

  const getInputClasses = (fieldName: string, disableTouch?: boolean) => {
    return commonGetInputClasses(formik, fieldName, disableTouch);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // Set the relative items
      if (strapiData.selectedSubcategories) {
        listing.subcategories = strapiData.selectedSubcategories.flatMap(subcategory => subcategory.id);
      }

      if (strapiData.selectedIndustry.id) {
        listing.industries = [strapiData.selectedIndustry.id];
      }

      handleSubmit({
        strapi,
        formik: formik,
        isNewListing: !listing.url,
        isService: false,
        listing: listing,
        nzOwned: nzOwned,
        setMessage,
        setStatus,
        setSubmitting,
        submitHandler: (strapiListing: any) => {
          if (strapiListing && strapiListing.attributes && strapiListing.attributes.slug) {
            const { slug } = strapiListing.attributes;
            if (!window.location.href.endsWith(slug)) {
              window.location.href = `/apps/pending/view/${slug}`;
              return;
            }
          }

          fetchData();
          if (!listing.url) {
            redirect("/apps/pending");
          }
        }
      });
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    }
  });

  // NB: This "ID" is actually the listing slug and not the real id
  // This is due to the legacy system using slugs instead of unique identifiers
  const { listingId } = props;
  const isNew = !listingId;

  const checkDelete = () => {
    if (confirm(`Delete ${listing.name}?`)) {
      setLoading(true);
      strapi.collection("apps")
        .delete(listing.id)
        .then(() => {
          redirect(`/apps/${props.isPending ? "pending" : "active"}`);
        });
    }
  };

  // Load the page
  React.useEffect(() => {
    if (!isLoaded) {
      setLoaded(true);
      fetchData()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          redirect("/apps");
        });

    }
  }, [listingId, formik, isLoaded]);

  React.useEffect(() => {
    if (formik.values.status !== listingStatus) {
      formik.setFieldValue("status", listingStatus);
    }
  }, [listingStatus]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  if (loading || !listing) {
    return <Loading />;
  }

  return (
    <>
      <form
        className="card card-custom"
        onSubmit={formik.handleSubmit}
        id="listing-form"
      >
        {/* begin::Header */}
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label font-weight-bolder text-dark">
              Listing Information
            </h3>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
              Information about this listing
            </span>
          </div>
          <div className="card-toolbar">
            <button
              type="submit"
              className="btn btn-success mr-2"
              disabled={
                formik.isSubmitting || (formik.touched && !formik.isValid)
              }
            >
              {isNew ? (user.admin ? "Create listing" : "Submit for review") : "Save changes"}
              {formik.isSubmitting}
            </button>
            <Link
              to={`/apps/active${query}`}
              className="btn btn-secondary"
            >
              Cancel
            </Link>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Form */}
        <div className="form">
          {/* begin::Body */}
          <div className="card-body">
            <FormAlertMessage {...message} />
            <FormDeclinedMessage status={listing.status} admin_note={listing.admin_note} />

            {/*{*/}
            {/*  (listing.special_offers || [])*/}
            {/*    .filter((s) => s.status === "D")*/}
            {/*    .map((specialOffer) =>*/}
            {/*      <div className={`mb-10 alert alert-custom alert-danger alert-dismissible`}*/}
            {/*           key={specialOffer.url}>*/}
            {/*        <div className="alert-text font-weight-bold">*/}
            {/*          <div>The special offer has been declined by an*/}
            {/*            admin. {listing.admin_note ? "The following note was left:" : "No note was left."}</div>*/}
            {/*          <div>{specialOffer.admin_note}</div>*/}
            {/*        </div>*/}
            {/*      </div>)*/}
            {/*}*/}

            <TextField
              label={"Listing name"}
              id={"name"}
              formik={formik}
            />

            <FileInput label={"Logo"} id={"logo"} formik={formik} />

            <TextField
              label={"Short description"}
              id={"short_description"}
              formik={formik}
            />

            <TextArea
              label={"Full description"}
              id={"long_description"}
              formik={formik}
            />

            <TextField
              label={"Link"}
              id={"link"}
              type={"url"}
              formik={formik}
            />

            <TextField
              label={"Free trial"}
              id={"free_trial"}
              type={"url"}
              formik={formik}
            />

            <TextField
              label={"Pricing"}
              id={"pricing"}
              formik={formik}
            />

            <SingleDropSelect
              id={"industries"}
              items={strapiData.industries}
              label={"Industries"}
              selectedItem={strapiData.selectedIndustry}
              setSelectedItem={(item: IIndustry) => updateStrapiData({ selectedIndustry: item })}
              formik={formik}
            />

            <RadioSelect
              id={"category"}
              items={strapiData.categories}
              label={"Category"}
              selectedItem={strapiData.selectedCategory}
              setSelectedItem={(item: ICategory) => updateStrapiData({ selectedCategory: item })}
              formik={formik}
            />

            {strapiData.selectedCategory && strapiData.selectedCategory.id !== 0 && (
              <MultiDropSelect
                id={"subcategories"}
                items={strapiData.filteredSubcategories}
                label={"Subcategories"}
                selectedItem={strapiData.selectedSubcategories}
                setSelectedItem={(item: ISubcategory[]) => updateStrapiData({ selectedSubcategories: item })}
                formik={formik}
              />
            )}

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                NZ Owned
              </label>
              <div className="col-lg-9">
                <label className="mb-0 alert alert-custom alert-outline-info">
                  <div className="alert-text font-weight-bold">
                    <label className="d-flex align-items-center m-0">
                      <label className="checkbox checkbox-lg flex-shrink-0 m-0 mr-4">
                        <input
                          type="checkbox"
                          name="nz_owned"
                          onChange={() => {
                            setNzOwned(!nzOwned);
                          }}
                          checked={nzOwned}
                        />
                        <span></span>
                      </label>
                      <div className="d-flex flex-column flex-grow-1">
                        <span>This listing is New Zealand owned and operated</span>
                      </div>
                    </label>
                  </div>
                </label>
              </div>
            </div>

            {user && user.admin && listing.status &&
              <>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Status
                  </label>
                  <div className="col-lg-9">
                    <input type="hidden" {...formik.getFieldProps("status")} />
                    {
                      [
                        ["Pending", "P", "primary"],
                        ["Approved", "A", "success"],
                        ["Declined", "D", "danger"]
                      ].map(([title, code, className]) =>
                        <button key={code} type="button"
                                className={`btn btn-${listingStatus === code ? className : "secondary"} mr-3`}
                                onClick={() => setListingStatus(code)}>{title}</button>
                      )
                    }
                  </div>
                </div>

                {listingStatus === "D" &&
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Reason for decline
                    </label>
                    <div className="col-lg-9">
                      <textarea
                        placeholder=""
                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                          "admin_note"
                        )}`}
                        {...formik.getFieldProps("admin_note")}
                      />
                      {formik.touched.admin_note && formik.errors.admin_note ? (
                        <div className="invalid-feedback">
                          {formik.errors.admin_note}
                        </div>
                      ) : null}
                    </div>
                  </div>
                }
              </>
            }
          </div>
          {/* end::Body */}
        </div>
        {/* end::Form */}
      </form>

      {listing.url &&
        <div className="card card-custom mt-5">
          {/* begin::Header */}
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark">
                Danger Zone
              </h3>
              <span className="text-muted font-weight-bold font-size-sm mt-1">
                Permanently delete this listing
              </span>
            </div>
            <div className="card-toolbar">
              <button
                type="button"
                onClick={checkDelete}
                className="btn btn-danger">
                {"Delete"}
              </button>
            </div>
          </div>
          {/* end::Header */}
        </div>
      }
    </>
  );
};
