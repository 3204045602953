import { MultiSelectProps, SelectItem } from "./commonTypes";
import Select from "react-select";
import React from "react";

interface MultiDropSelectProps<T extends SelectItem> extends MultiSelectProps<T> {
  max?: number;
}

export default function MultiDropSelect<T extends SelectItem>(props: MultiDropSelectProps<T>) {
  const { max = 3 } = props;

  const getOptions = () => {
    // If max or above selected, no options
    if (props.selectedItem && props.selectedItem.length >= max) {
      return [];
    }

    return props.items.map((item) => ({
      label: item.name,
      value: item.id.toString()
    }));
  };

  const getSelectedOptions = () => {
    if (props.selectedItem) {
      return props.selectedItem.map((item) => ({
        label: item.name,
        value: item.id.toString()
      }));
    }

    return [];
  };

  return (
    <div className="form-group row">
      <label className="col-xl-3 col-lg-3 col-form-label">
        {props.label}
        <br />
        <em className="text-muted">Max {max}</em>
      </label>
      <div className="col-lg-9">
        <Select
          className="basic-single mb-0"
          classNamePrefix="select"
          isClearable={false}
          isSearchable={true}
          isMulti={true}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          options={getOptions()}
          value={getSelectedOptions()}
          onChange={(values) => {
            if (values && props.items) {
              const selectedIds = values.map(val => parseInt(val.value));
              const selectedOptions = props.items.filter(item => selectedIds.includes(item.id));
              props.setSelectedItem(selectedOptions);
              //listing.subcategories = values.map(val => parseInt(val.value));
            } else {
              //listing.subcategories = [];
            }
            // setListing({
            //   ...listing
            // });
          }}
          noOptionsMessage={() => {
            // if ((listing.subcategories || []).length >= 3) {
            //   return "Max 3 already selected";
            // }

            return "No results found";
          }}
        />

        {props.items && props.items.map((item, index) => {
          const checked = ((props.selectedItem || [])).includes(item);
          return (
            <input
              key={`${props.label}-${item.id}-${index}`}
              className="d-none"
              type="checkbox"
              name={props.id}
              value={item.id}
              checked={checked}
              onChange={() => {
                // null
              }}
            />
          );
        })}

        {props.formik.touched[props.id] && props.formik.errors[props.id] ? (
          <div className="invalid-feedback">
            {props.formik.errors[props.id]}
          </div>
        ) : null}
      </div>
    </div>
  );
}