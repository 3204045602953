/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { selectAuthUser } from "../../../../../app/constraints";

export function UserProfileDropdown () {
  const user = useSelector(selectAuthUser);

  if (!user) {
    return (<></>);
  }

  const Badge = () => {
    if (user.admin) {
      return (
        <span className="badge badge-success">SUPER ADMIN</span>
      );
    }

    if (user.trial) {
      return (
        <span className="badge badge-warning">TRIAL</span>
      );
    }

    if (user.upgraded) {
      return (
        <span className="badge badge-success">PREMIUM</span>
      );
    }

    return (
      <span className="badge badge-danger">FREE</span>
    );
  };

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span
            className="text-muted font-weight-bold font-size-base d-none d-lg-inline mr-1">
            Hi,
          </span>{" "}
          <span
            className="text-dark-50 font-weight-bolder font-size-base d-none d-lg-inline">
            {user.first_name || user.username}{" "}

            <Badge />
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <div className="navi navi-spacer-x-0 pt-3">
          <Link to="/account" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  My Account
                </div>
                <div className="text-muted">
                  Update personal information or change password
                </div>
              </div>
            </div>
          </Link>
          <div className="navi-separator mt-3"></div>

          <div className="navi-footer  px-8 py-5">
            <Link
              to="/logout"
              className="btn btn-light-primary font-weight-bold"
            >
              Sign Out
            </Link>
            {/* <a href="#" className="btn btn-clean font-weight-bold">
              Upgrade Plan
            </a> */}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
