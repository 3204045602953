import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import * as auth from "../Auth";
import { injectIntl } from "react-intl";
import Loading from "../../Loading";
import moment from "moment";

import { getQueryParams } from "../../../_metronic/_helpers";
import Toggle from "../Toggle/Toggle";
import { ICard } from "../../../interface/ICard";
import { Redirect } from "react-router";
import Strapi from "../Strapi/Strapi";
import Plan from "../../../interface/IPlan";
import { redirect } from "../Strapi/Func";
import { selectAuthUser } from "../../constraints";
import { AxiosResponse } from "axios";


const Subscription = () => {
  const strapi = useSelector((state: any) => state.auth.strapi as Strapi);
  const user = useSelector(selectAuthUser);
  const plans = useSelector((state: any) => state.auth.plans as Record<any, Plan>);

  const queryParams = getQueryParams() as any;

  const [subscriptionAction, setSubscriptionAction] = useState("CREATE");
  const [loading, setLoading] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState<any>(null);
  const [quarterly, setQuarterly] = useState(queryParams.quarterly === true);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

  const [paymentMethods, setPaymentMethods] = useState<ICard[] | null>(null);

  const subUrl = "/api/me/stripe/subscriptions";

  React.useEffect(() => {
    if (strapi) {
      strapi.collection("me")
        .getCustom("stripe/payment-methods")
        .then(setPaymentMethods);
    }
  }, [strapi]);

  const [plan, setPlan] = useState<Plan>();
  React.useEffect(() => {
    if (plans) {
      setPlan(plans.annually);
    }
  }, [plans]);


  React.useEffect(() => {
    setPlan(quarterly ? plans.quarterly : plans.annually);
  }, [quarterly]);

  React.useEffect(() => {
    strapi.collection("me")
      .getCustom("stripe/subscriptions")
      .then(setSubscriptionInfo)
      .catch(err => {
        if (err && err.response && err.response.status === 404) {
          setSubscriptionInfo(404);
        } else {
          alert("An unknown error occurred");
          setLoading(false);
          redirect("/", { skipChecks: true });
        }
      });
  }, []);

  React.useEffect(() => {
    let newAction = "CREATE";

    if (subscriptionInfo) {
      if (subscriptionInfo === 404) newAction = "CREATE";
      else if (subscriptionInfo.cancel_at || subscriptionInfo.cancel_at_period_end) newAction = "RESUME";
      else if (subscriptionInfo.status === "trialing") newAction = "TRIALING";
      else if (subscriptionInfo.status === "active") newAction = "UPDATE";
    }

    setSubscriptionAction(newAction);
  }, [subscriptionInfo]);

  if (subscriptionInfo === null || paymentMethods === null || !plans || loading) {
    return <Loading />;
  }

  const checkCancel = () => {
    if (confirm("Cancel your premium account subscription at the end of the current cycle?\n\nYou may reactivate your premium subscription at any time.")) {
      setLoading(true);
      strapi.axios_connection.delete(subUrl)
        .then(() => window.location.reload())
        .catch((err) => {
          alert(err.message);
          setLoading(false);
        });
    }
  };

  const numberOrUnlimited = (number: number) => {
    if (number === -1) {
      return "Unlimited*";
    }
    return number;
  };

  const handleSubmit = () => {
    if (!plan) {
      alert("An unknown error occurred");
      setLoading(false);
      window.location.reload();
      return;
    }

    const handleSubscriptionAction = () => {
      switch (subscriptionAction) {
        case "RESUME":
          return strapi.axios_connection
            .put(subUrl, {
              paymentMethod: selectedPaymentMethod
            });

        case "UPDATE":
          return strapi.axios_connection
            .patch(subUrl, {
              paymentMethod: selectedPaymentMethod
            });

        case "CREATE":
        default:
          return strapi.axios_connection
            .post(subUrl,
              {
                paymentMethod: selectedPaymentMethod,
                product: plan.slug,
                price: plan.id
              }
            )
            .catch(err => {
              if (err.response.status === 400 && err.response.data.error) {
                if (err.response.data.error.message === "Customer is already subscribed") {
                  window.location.reload();
                  return;
                }
              }

              throw err;
            });
      }
    };

    handleSubscriptionAction()
      .then((res) => {
        const r = res as AxiosResponse;
        if (r.status === 200 && r.data) {
          return r.data;
        }
        throw new Error("Error occurred");
      })
      .then(() => window.location.reload())
      .catch((err) => {
        alert(err.message);
        setLoading(false);
      });
  };

  const CurrentSubscriptionCard = () => {
    if (subscriptionAction === "UPDATE" || subscriptionAction === "TRIALING") {
      return (
        <>
          <div
            className="alert alert-custom alert-light-success fade show mb-2"
            role="alert">
            <div className="alert-text">Your account subscription is currently active.</div>
          </div>

          <div className={`card card-custom gutter-b`}>
            <div className="card-body p-0">
              <div className="card-spacer">
                <div>
                  <span className="text-dark-50 font-weight-bold">Active since:</span>{" "}
                  <span
                    className="text-muted font-weight-bold">{moment.unix(subscriptionInfo.created).format("MMMM Do YYYY")}</span>
                </div>

                <div className="mt-2">
                  <span className="text-dark-50 font-weight-bold">Current period:</span>{" "}
                  <span className="text-muted font-weight-bold">
                    <span>{moment.unix(subscriptionInfo.current_period_start).format("MMMM Do YYYY")}</span>
                    <span>&nbsp;-&nbsp;</span>
                    <span>{moment.unix(subscriptionInfo.current_period_end).format("MMMM Do YYYY")}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    if (subscriptionAction === "RESUME") {
      return (
        <div
          className="alert alert-custom alert-light-warning fade show mb-2"
          role="alert">
          <div className="alert-text font-weight-bold">
            Your active account subscription is set to
            expire <span>{moment.unix(subscriptionInfo.cancel_at || subscriptionInfo.current_period_end).format("MMMM Do YYYY, h:mm:ss a")}</span>.
          </div>
        </div>
      );
    }

    return null;
  };

  if (user && user.admin) {
    return (
      <div
        className="alert alert-custom alert-light-warning fade show mb-2"
        role="alert">
        <div className="alert-text font-weight-bold">
          Super Admin accounts are ineligible for paid account subscriptions. All premium features are already
          available.
        </div>
      </div>
    );
  }

  if (paymentMethods.length === 0) {
    return <Redirect to="/account/payment_methods/subscription" />;
  }

  return (
    <>
      {subscriptionAction !== "TRIALING" && <>
        <Toggle checked={quarterly} onChange={setQuarterly} leftLabel="Annual" rightLabel="Quarterly" />

        <div className="card card-custom my-3">
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark">{plan?.title}</h3>
              <span className="text-muted font-weight-bold font-size-sm mt-1">Billed {plan?.intervalText}</span>
            </div>
          </div>

          {/* begin::Form */}
          {plan &&
            <div className="form">
              {/* begin::Body */}
              <div className="card-body">
                <h2
                  className="text-dark-75 font-weight-bolder font-size-h3">${plan?.price.toFixed(2)} {plan.firstInterval}</h2>
                {
                  plan.comparePrice
                    ? <p className="text-muted mb-3">Renews at ${plan?.comparePrice.toFixed(2)}/{plan.interval}</p>
                    : null}

                <div className="mb-2">
                <span
                  className="text-dark-50 font-weight-bold">{numberOrUnlimited(plan.features.appListings)}</span>{" "}
                  <span className="text-muted font-weight-bold">app listings</span>
                </div>

                <div className="mt-2">
                <span
                  className="text-dark-50 font-weight-bold">{numberOrUnlimited(plan.features.serviceListings)}</span>{" "}
                  <span className="text-muted font-weight-bold">cloud expert listings</span>
                </div>
              </div>
            </div>
          }
        </div>
      </>}

      <CurrentSubscriptionCard />

      <div className="card card-custom mb-5">
        {/* begin::Form */}
        <div className="form">
          {/* begin::Body */}
          <div className="card-body">
            <div className="row m-0">
              <select
                value={selectedPaymentMethod}
                onChange={(ev) => {
                  setSelectedPaymentMethod(ev.target.value);
                }}
                className="form-control form-control-lg form-control-solid"
                style={{
                  flex: 1
                }}>
                <option defaultChecked disabled={!!selectedPaymentMethod} value="">--- Select payment method ---
                </option>
                {paymentMethods.map((paymentMethod) =>
                  <option
                    key={paymentMethod.id}
                    value={paymentMethod.id}>
                    {/*selected={paymentMethod.id === selectedPaymentMethod}*/}
                    {paymentMethod.card.brand} {paymentMethod.card.last4}
                  </option>
                )}
              </select>

              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-success ml-3"
                disabled={!selectedPaymentMethod}>
                {
                  (() => {
                    if (subscriptionAction === "RESUME") return "Reactivate subscription";
                    else if (subscriptionAction === "TRIALING") return "Update payment method";
                    else if (subscriptionAction === "UPDATE") return "Update payment method";
                    else return "Activate subscription";
                  })()
                }
              </button>
            </div>
          </div>
        </div>
      </div>

      {["UPDATE", "TRIALING"].includes(subscriptionAction) &&
        <div className="card card-custom mt-5">
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark">
                Danger Zone
              </h3>
              <span className="text-muted font-weight-bold font-size-sm mt-1">
                Cancel active subscription
              </span>
            </div>
            <div className="card-toolbar">
              <button
                type="button"
                onClick={checkCancel}
                className="btn btn-danger">
                {"Cancel subscription"}
              </button>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default injectIntl(connect(null, auth.actions)(Subscription));
