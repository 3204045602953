import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { CreateBaseSiteUrl } from "../../../constraints";
import { User } from "../../Strapi/User";

function Registration (props) {
  const initialValues = {
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirm: "",
    redirect_url: sessionStorage.getItem("nv-redirect_url") ?? "",
    acceptTerms: false
  };

  const { intl, connectStrapi } = props;
  const [loading, setLoading] = useState(false);
  const [redirectTo, redirect] = useState("");

  const RegistrationSchema = Yup.object().shape({
    first_name: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD"
      })
    ),
    last_name: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD"
      })
    ),
    email: Yup.string().email(
      intl.formatMessage({
        id: "AUTH.VALIDATION.INVALID_EMAIL"
      })
    ).required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD"
      })
    ),
    password: Yup.string().min(8, "Minimum 8 symbols").required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD"
      })
    ),
    password_confirm: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD"
      })
    ).when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        intl.formatMessage({
          id: "AUTH.VALIDATION.PASSWORD_MATCH"
        })
      )
    }),
    acceptTerms: Yup.bool().required(
      "You must accept the terms and conditions"
    )
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();

      User.register(values.email, values.password, values.first_name,
        values.last_name, values.newsletter).then(response => {
        if (response) {
          connectStrapi(response);
          window.location = "/dashboard";
        }
      }).catch(err => {
        setStatus(
          intl.formatMessage({
            id: "AUTH.REGISTER.FAILED"
          })
        );
        if (err && err.status === 400) {
          if (err.message === "Email or Username are already taken") {
            formik.setFieldError("email", err.message);
          }
        }
      }).finally(() => {
        setSubmitting(false);
        disableLoading();
      });
    }
  });

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.REGISTER.DESC" />
        </p>
        <div>
          <span className="font-weight-bold text-dark-50">Already have an account?</span>
          <Link to={`/auth/login${window.location.search}`}
                className="font-weight-bold ml-2" id="kt_login_signup">Sign
            In</Link>
        </div>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        <input
          type="hidden"
          name="redirect_url"
          {...formik.getFieldProps("redirect_url")} />

        {/* begin: Alert */}
        {formik.status && (
          <div
            className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: first_name/last_name row */}
        <div className="row">
          {/* begin: first_name */}
          <div className="col-md-6">
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="First name"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "first_name"
                )}`}
                name="first_name"
                {...formik.getFieldProps("first_name")}
              />
              {formik.touched.first_name && formik.errors.first_name ? (
                <div className="fv-plugins-message-container">
                  <div
                    className="fv-help-block">{formik.errors.first_name}</div>
                </div>
              ) : null}
            </div>
          </div>
          {/* end: first_name */}

          {/* begin: last_name */}
          <div className="col-md-6">
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Last name"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "last_name"
                )}`}
                name="last_name"
                {...formik.getFieldProps("last_name")}
              />
              {formik.touched.last_name && formik.errors.last_name ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.last_name}</div>
                </div>
              ) : null}
            </div>
          </div>
          {/* end: last_name */}
        </div>
        {/* end: first_name/last_name row */}

        {/* begin: email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: email */}

        {/* begin: password/password_confirm row */}
        <div className="row">
          {/* begin: password */}
          <div className="col-md-6">
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Password"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
            </div>
          </div>
          {/* end: password */}

          {/* begin: password_confirm */}
          <div className="col-md-6">
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Confirm Password"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password_confirm"
                )}`}
                name="password_confirm"
                {...formik.getFieldProps("password_confirm")}
              />
            </div>
          </div>
          {/* end: password_confirm */}

        </div>
        {(formik.touched.password && formik.errors.password) ||
        (formik.touched.password_confirm && formik.errors.password_confirm) ? (
          <div className="row">
            <div className="col-sm-12 mb-5">
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.password}
                  {" "}
                  {formik.errors.password_confirm}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="newsletter"
              {...formik.getFieldProps("newsletter")}
            />
            <span />&nbsp;
            Let us keep me in the loop with The Right Tool
          </label>
          {formik.touched.newsletter && formik.errors.newsletter ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.newsletter}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              {...formik.getFieldProps("acceptTerms")}
            />
            <span />&nbsp;
            I agree to the&nbsp;<a href={CreateBaseSiteUrl("/terms-of-use")}
                                   target="_blank"
                                   rel="noopener noreferrer">Terms &amp; Conditions</a>.
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}

        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={formik.isSubmitting || !formik.values.acceptTerms}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Sign Up</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
