import Strapi from "../modules/Strapi/Strapi";
import Cookies from "./Cookies";

const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
export type LocallyStoredObject = {
  value: string
  timestamp: number
}

export const getStoreItem = (key: string) => {
  const rawItem = localStorage.getItem(btoa(key)) as string | null;
  if (rawItem && base64regex.test(rawItem)) {
    const item = JSON.parse(atob(rawItem)) as LocallyStoredObject;
    if (item && item.value && item.timestamp) {
      if (item.timestamp > new Date().getTime()) {
        return item.value;
      }

      localStorage.removeItem(btoa(key));
    }
  }

  return null;
};

export const setStoreItem = (key: string, value: string, exdays = 30) => {
  const d = new Date();
  d.setTime((d.getTime() + (exdays * 24 * 60 * 60 * 1000)));
  localStorage.setItem(btoa(key), btoa(JSON.stringify({
    value,
    timestamp: d.getTime()
  } as LocallyStoredObject)));
};

export default {
  set: setStoreItem,
  get: getStoreItem,
  remove: (key: string) => localStorage.removeItem(btoa(key)),
  clear: () => {
    if (window.strapi) {
      (window.strapi as Strapi | undefined) = undefined;
    }

    localStorage.clear();

    // Also clear cookies
    Cookies.remove("AUTH_TOKEN");
  }
};