import React from "react";
import { commonGetInputClasses } from "../../app/pages/Listings/CommonView";
import classnames from "classnames";

type TextFieldProps = {
  label: string
  id: string
  placeholder?: string
  type?: "text" | "email" | "password" | "url" | "tel"

  formik: any
}
export default function TextField({
                                    id,
                                    label,
                                    placeholder = "",
                                    type = "text",
                                    formik
                                  }: TextFieldProps) {
  const getInputClasses = (fieldName: string, disableTouch?: boolean) => {
    return commonGetInputClasses(formik, fieldName, disableTouch);
  };

  const inputClass = classnames(
    "form-control form-control-lg form-control-solid",
    getInputClasses(id)
  );

  return (
    <div className="form-group row">
      <label className="col-xl-3 col-lg-3 col-form-label">
        {label}
      </label>
      <div className="col-lg-9">
        <input
          type={type}
          placeholder={placeholder}
          className={inputClass}
          {...formik.getFieldProps(id)}
        />
        {formik.touched[id] && formik.errors[id] ? (
          <div className="invalid-feedback">
            {formik.errors[id]}
          </div>
        ) : null}
      </div>
    </div>
  );
}