import React, { ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import { IAppListing } from "../../../interface/IAppListing";
import { getListings } from "../../modules/Auth/_redux/authCrud";
import Loading from "../../Loading";
import Dropdown from "react-bootstrap/Dropdown";

import { ISpecialOffer } from "../../../interface/ISpecialOffer";
import { IServiceListing } from "../../../interface/IServiceListing";

export interface ISpecialOffersProps {
  title: string;
  caption: string;
  baseName: string;
  isPending: boolean;
}

export const SpecialOffers = ({ title, caption, baseName, isPending }: ISpecialOffersProps): ReactElement => {
//  useSubheader().setTitle("Special Offers");

  const [loading, setLoading] = useState(true);
  const [specialOffers, setSpecialOffers] = useState<ISpecialOffer[]>([]);
  const [listings, setListings] = useState<Array<IAppListing | IServiceListing>>([]);
  const [declineItem, setDeclineItem] = useState<ISpecialOffer | null>(null);

  const [query] = useState(window.location.search);

  const loadListing = () => {
    getListings(baseName, (o) => o, { legacyPath: baseName })("").then((allListings) => {
      console.log(baseName);
      setListings(allListings);
      getListings<ISpecialOffer>(`${isPending ? "/pending" : ""}/special-offers${baseName}`, i => i, {})(query)
        .then((allSpecialOffers) => {
          setLoading(false);
          setSpecialOffers(allSpecialOffers);
        });
    });
  };

  React.useEffect(() => {
    setLoading(true);
    loadListing();
  }, [baseName, query]);

  if (loading) {
    return <Loading />;
  }

  const getListingId = (listing: IAppListing | IServiceListing | ISpecialOffer) => {
    const splitUrl = listing.url.split("/");
    return splitUrl[splitUrl.length - 1] || splitUrl[splitUrl.length - 2];
  };

  const approveSpecialOffer = (specialOffer: ISpecialOffer) => {
    const formData = new FormData();
    formData.append("status", "A");
    setLoading(true);
    //patchItem(specialOffer, formData).catch(loadListing).then(loadListing);
  };

  const declineSpecialOffer = (specialOffer: ISpecialOffer) => {
    setDeclineItem(specialOffer);
  };

  const declineSpecialOfferWithNote = (specialOffer: ISpecialOffer) => {
    const declineInput = document.getElementById("decline-input") as HTMLInputElement;
    const note = declineInput.value;

    const formData = new FormData();
    formData.append("status", "D");
    formData.append("admin_note", note);
    setLoading(true);
    //patchItem(specialOffer, formData).catch(loadListing).then(loadListing);

    setDeclineItem(null);
  };

  return (
    <>
      {declineItem &&
        <div className="form-modal form-modal--open">
          <div className="form-modal__backdrop" onClick={() => setDeclineItem(null)}>&nbsp;</div>

          <div className="form-modal__content">
            <div className="card card-custom mb-0">
              <div className="card card-custom">
                {/* begin::Header */}
                <div className="card-header py-3">
                  <div className="card-title align-items-start flex-column">
                    <h4 className="card-label font-weight-bolder text-dark">
                      Decline Special Offer
                    </h4>
                    <span className="text-muted font-weight-bold font-size-sm mt-1">
                      {"Provide detail to the listing owner"}
                    </span>
                  </div>

                  <div className="card-toolbar">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setDeclineItem(null)}
                    >
                      Close
                    </button>
                  </div>
                </div>

                <div className="card-body">
                  <div className="tab-content text-center">
                    <div>
                      <span className="card-label font-weight-bolder font-size-lg text-dark">
                        {"Reason for decline"}
                      </span>
                    </div>

                    <div className="form-group my-3">
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid`}
                        placeholder="Reason for decline"
                        id="decline-input" />
                    </div>

                    <div className="my-3">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => declineSpecialOfferWithNote(declineItem)}>
                        {"Decline special offer"}
                      </button>
                    </div>
                  </div>
                </div>
                {/* end::Body */}
              </div>
            </div>
          </div>
        </div>}

      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">{title}</span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">{caption}</span>
          </h3>
          <div className="card-toolbar">
            {
              isPending
                ? <Link to={`/special-offers${baseName}active`}
                        className="btn btn-secondary font-weight-bolder font-size-sm">Active offers</Link>
                : <Link to={`/special-offers${baseName}pending`}
                        className="btn btn-secondary font-weight-bolder font-size-sm">View pending</Link>
            }
          </div>
        </div>

        {/* Body */}
        <div className="card-body pt-0 pb-3">
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-7" style={{ minWidth: "250px" }}><span className="text-dark-75">Listing</span></th>
                  <th style={{ width: 230 }} />
                </tr>
                </thead>
                <tbody>
                {specialOffers.map((specialOffer) => {
                  const listing = listings.find((l) => l.url === specialOffer.listing);

                  return (
                    <tr key={specialOffer.url}>
                      <td className="pl-0 py-8">
                        <div className="d-flex align-items-center">
                          <div>
                            <div>
                              <h5 className="text-dark-50 mb-1 font-size-lg">
                                  <span className="font-weight-bolder text-dark-75">
                                    {specialOffer.title}
                                    {specialOffer.status === "D" &&
                                      <span className="badge badge-danger ml-2">DECLINED</span>}
                                  </span>
                              </h5>
                              <span className="text-dark-75 mb-1 font-size-lg">{specialOffer.description}</span>

                              <div className="mt-3">
                                <a href={specialOffer.link} target="_blank" rel="nofollow noreferrer"
                                   className="btn btn-sm btn-outline-primary">{specialOffer.label}</a>
                              </div>
                            </div>

                            {listing &&
                              <div className="font-size-sm mt-3">
                                {"on "}
                                <Link to={`${baseName}active/view/${getListingId(listing)}`}>{listing.name}</Link>
                              </div>}
                          </div>
                        </div>
                      </td>
                      <td className="pr-0 text-right">
                        <Dropdown drop="down" alignRight className="d-sm-none">
                          <Dropdown.Toggle id={`options-${specialOffer.url}`} className="p-4"></Dropdown.Toggle>
                          <Dropdown.Menu className="p-3">
                            {isPending &&
                              <div className="row m-0">
                                <span onClick={() => approveSpecialOffer(specialOffer)}
                                      className="btn col btn-light-success font-weight-bolder font-size-sm mr-3">Approve</span>
                                <span onClick={() => declineSpecialOffer(specialOffer)}
                                      className="btn col btn-light-danger font-weight-bolder font-size-sm">Decline</span>
                              </div>
                            }
                          </Dropdown.Menu>
                        </Dropdown>

                        <div className="d-none d-sm-block">
                          {isPending && <>
                            <span onClick={() => approveSpecialOffer(specialOffer)}
                                  className="btn btn-light-success font-weight-bolder font-size-sm mr-3">Approve</span>
                            <span onClick={() => declineSpecialOffer(specialOffer)}
                                  className="btn btn-light-danger font-weight-bolder font-size-sm">Decline</span>
                          </>}
                        </div>
                      </td>
                    </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
