import React, { ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

import { IAppListing } from "../../../interface/IAppListing";
import { IServiceListing } from "../../../interface/IServiceListing";

import Loading from "../../Loading";
import { ANALYTICS_ENABLED } from "../../constraints";

export interface IListingsProps {
  fn: (query: string) => Promise<IAppListing[]>;
  title: string;
  caption: string;

  linkTo: (query: string) => string;
  linkText: string;
  createLink: (query: string) => string;
  viewLink: (appId: string) => string;
  analyticsLink: (appId: string) => string;

  query: string;
}

export const Apps = ({
                       fn,
                       title,
                       caption,
                       linkTo,
                       linkText,
                       createLink,
                       viewLink,
                       analyticsLink,
                       query
                     }: IListingsProps): ReactElement => {
  // useSubheader().setTitle("Listings");

  const [loading, setLoading] = useState(true);
  const [listings, setListings] = useState<Array<IAppListing | IServiceListing>>([]);

  React.useEffect(() => {
    setLoading(true);

    fn(query)
      .then((allListings) => {
        setListings(allListings);
        setLoading(false);
      });
  }, [fn, query]);

  if (loading) {
    return <Loading />;
  }

  const getListingId = (listing: IAppListing | IServiceListing) => {
    if (!listing.url) {
      return listing.id.toString();
    }

    const splitUrl = listing.url.split("/");
    return splitUrl[splitUrl.length - 1] || splitUrl[splitUrl.length - 2];
  };

  const renderTable = () => {
    return (
      <div className="card-body pt-0 pb-3" id="apps-listings">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead>
              <tr className="text-left text-uppercase">
                <th className="pl-7" style={{ minWidth: "250px" }}>
                  <span className="text-dark-75">Listing</span>
                </th>
                <th style={{ width: 170 }} />
              </tr>
              </thead>
              <tbody>
              {listings.map((listing) =>
                <tr key={`app_${listing.id}`}>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label" style={{
                            backgroundImage: `url(${listing.logo})`
                          }}>&nbsp;</span>
                      </div>

                      <div>
                        <div>
                          <Link to={viewLink(getListingId(listing))}
                                className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{listing.name}</Link>
                          {" "}
                          {listing.status === "DENIED" &&
                            <span className="badge badge-danger">DECLINED</span>}
                          {listing.status === "PENDING" &&
                            <span className="badge badge-info">PENDING</span>}
                        </div>

                        {
                          "created_by_obj" in listing &&
                          listing.created_by_obj &&
                          <div className="font-size-sm">by <a
                            href={`mailto:${listing.created_by_obj.email}`}>{listing.created_by_obj.first_name} &lt;{listing.created_by_obj.email}&gt;</a>
                          </div>
                        }
                      </div>
                    </div>
                  </td>

                  <td className="pr-0 text-right">
                    <Dropdown drop="down" alignRight className="d-sm-none">
                      <Dropdown.Toggle id={`options-${getListingId(listing)}`}
                                       className="p-4"></Dropdown.Toggle>
                      <Dropdown.Menu className="p-3">
                        <div className="row m-0">
                          {ANALYTICS_ENABLED &&
                            <Link to={analyticsLink(getListingId(listing))}
                                  className="btn col btn-secondary mr-3 font-weight-bolder font-size-sm">Analytics</Link>}
                          <Link to={viewLink(getListingId(listing))}
                                className="btn col btn-light-primary font-weight-bolder font-size-sm">Edit</Link>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>

                    <div className="d-none d-sm-block">
                      {ANALYTICS_ENABLED && <Link to={analyticsLink(getListingId(listing))}
                                                  className="btn btn-secondary mr-3 font-weight-bolder font-size-sm">Analytics</Link>}
                      <Link to={viewLink(getListingId(listing))}
                            className="btn btn-light-primary font-weight-bolder font-size-sm">Edit</Link>
                    </div>
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`card card-custom`}>
        {/* Head */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">{title}</span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">{caption}</span>
          </h3>
          <div className="card-toolbar">
            <Link to={linkTo(query)}
                  className="btn btn-secondary font-weight-bolder mr-5 font-size-sm">{linkText}</Link>
            <Link to={createLink(query)} className="btn btn-primary font-weight-bolder font-size-sm">Create
              new</Link>
          </div>
        </div>

        {/* Body */}
        {renderTable()}
      </div>
    </>
  );
};
