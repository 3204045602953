import Strapi from "./Strapi";
import { StrapiReviewResponse, StrapiTeamMemberResponse } from "./types/StrapiResponses";
import _ from "lodash";
import { convertTeam } from "./ApiConverter";
import { APIResponseData } from "./types/StrapiTypesConnector";
import { ConvertedMiniResponse, LinkUrl, StrapiPartResponse } from "./types/ConvertedTypes";
import { BASE_SITE } from "../../constraints";

type RedirectOptionalProps = {
  errorMessage?: string,
  errorObject?: any
  noRedirectFrom?: string[]
  skipChecks?: boolean
}

function redirect(url: string, { errorMessage, errorObject, noRedirectFrom, skipChecks }: RedirectOptionalProps = {}) {
  // To prevent redirect loops, we dont want to redirect if we are already on the page
  const pathToCheck = window.location.pathname;

  if (!skipChecks) {
    // If the noRedirectFrom paramater is set, check each url
    if (noRedirectFrom) {
      if (noRedirectFrom.some(path => pathToCheck.startsWith(path))) {
        // Don't redirect
        return;
      }
    }

    if (pathToCheck.startsWith(url)) {
      // Don't redirect
      return;
    }
  }

  if (process.env.NODE_ENV === "development") {
    // Log errors instead of redirect
    if (errorMessage) {
      console.log(errorMessage);
    }

    if (errorObject) {
      console.log(errorObject);
    }

    console.debug("NB: Would have redirected to: " + url + " from: " + pathToCheck + "");

    return;
  }

  window.location.href = url;
}

async function getListingRating(listingId: number, type: "app" | "service" = "app") {
  const s = await Strapi.connect();
  return await s.collection(`reviews/${type}/${listingId}`)
    .getAll<StrapiReviewResponse>()
    .then(reviews => {
      if (reviews) {
        return _.mean(
          reviews
            .filter(r => r.attributes.rating)
            .map(r => r.attributes.rating)
        );
      }
      return null;
    });
}

async function getTeamMembers(listingId: number, type: "account" | "service" = "service") {
  const s = await Strapi.connect();
  return await s.collection("team-members")
    .addFilter(`${type}`, { id: { $eq: listingId } })
    .getAll<StrapiTeamMemberResponse>()
    .then(teamMembers => {
      if (teamMembers) {
        return teamMembers.map(convertTeam);
      }

      return [];
    }).catch(err => console.error(err));
}

function getFirstStrapiImageUrl(imageField: any) {
  if (imageField && imageField.data && imageField.data.length > 0) {
    return getStrapiImageUrl(imageField.data[0]);
  }

  return null;
}

function getStrapiImageUrl(imageObj: APIResponseData<"plugin::upload.file">) {
  if (imageObj && imageObj.attributes && imageObj.attributes.url) {
    return `${process.env.REACT_APP_STRAPI_URL}${imageObj.attributes.url}`;
  }

  return null;
}

type StrapiRelation = { data?: StrapiPartResponse[] }

function convertRelationArr(relation: any | StrapiRelation, mappingFunc: (item: any) => any) {
  if (relation) {
    if (Array.isArray(relation)) {
      return relation.map(mappingFunc);
    }

    if (relation.data && Array.isArray(relation.data)) {
      return relation.data.map(mappingFunc);
    }
  }
}

function convertRelation(relation: unknown | StrapiRelation, link: string) {
  if (relation && link) {
    return relationList(relation as StrapiRelation, link as LinkUrl) as ConvertedMiniResponse[];
  }
  return [] as ConvertedMiniResponse[];
}

function relationList(relation: StrapiRelation | null, link: LinkUrl) {
  if (relation && relation.data) {
    return relation.data.map(r => {
      if (!r.attributes) {
        const { ...attributes } = r as Record<string, any>;
        return {
          id: attributes.id,
          name: attributes.name,
          url: calculateSlug(r, link)
        };
      }
      return {
        id: r.id,
        url: calculateSlug(r, link),
        name: r.attributes.name
      } as ConvertedMiniResponse;
    });
  }
  return [] as ConvertedMiniResponse[];
}

function calculateSlug(strapiDataObj: StrapiPartResponse, parentPages: string[] | string = [], defaultValue: null | string = null) {
  if (strapiDataObj && strapiDataObj.attributes && strapiDataObj.attributes.slug) {
    const parts = [`${BASE_SITE}`].concat(parentPages);
    parts.push(strapiDataObj.attributes.slug);
    return `${parts.join("/")}/`;
  }

  return defaultValue;
}

function formDataToJsonObject(formData: FormData) {
  const object: Record<string, any> = {};
  formData.forEach((value, key) => {
    // Reflect.has in favor of: object.hasOwnProperty(key)
    if (!Reflect.has(object, key)) {
      object[key] = value;
      return;
    }
    if (!Array.isArray(object[key])) {
      object[key] = [object[key]];
    }
    object[key].push(value);
  });

  return object;
}

// Attempts to take an object and return a strapi connect field
type ParseableRelation_single = string | number
type ParseableRelation_array = (ParseableRelation_single)[] | string[] | number[]
type ParseableRelation_object = { data: ParseableRelation_single } | { data: ParseableRelation_array }

type ParseableRelationObjects = ParseableRelation_single | ParseableRelation_array | ParseableRelation_object
type ParsedRelationsSet = {
  set: number[]
}
export type StrapiPostRecord = Record<string, string | number | boolean | ParsedRelationsSet | null>;

// Annoyingly both strapi and this legacy app does not return things in a consistent way,
// so this helper function is needed to try and catch all variations
function connectRelation(val: ParseableRelationObjects) {
  if (!val) {
    return null;
  }

  const toNumber = (num: number | string) => {
    if (_.isString(num) && _.isEmpty(num)) {
      return null;
    }

    const return_num = _.toNumber(num);
    if (_.isInteger(return_num) && return_num >= 0) {
      return return_num;
    }

    return null;
  };

  try {
    let r, tmp: ParseableRelation_single | ParseableRelation_array;

    // Check if this is data object
    if (typeof val === "object" && "data" in val) {
      tmp = val.data;
    } else {
      tmp = val;
    }

    // Check if array
    if (_.isArray(tmp)) {
      r = tmp.map(i => toNumber(i));
    } else {
      r = [toNumber(tmp)];
    }

    // Filter null values
    if (r && _.isArray(r)) {
      r = r.filter(i => !_.isNull(i));

      // There is nothing left, return null
      if (r.length === 0) {
        r = null;
      }
    }

    if (!r) {
      return null;
    }

    return {
      set: r
    } as ParsedRelationsSet;
  } catch (e) {
    return null;
  }
}

export {
  getListingRating,
  getTeamMembers,
  getFirstStrapiImageUrl,
  getStrapiImageUrl,
  convertRelation,
  convertRelationArr,
  calculateSlug,
  formDataToJsonObject,
  connectRelation,
  redirect
};

export type {
  StrapiRelation
};