import React, { useState } from "react";
import { useFormik } from "formik";
import { Link, Redirect } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import Loading from "../../Loading";
import { IIndustry } from "../../../interface/IIndustry";
import { IServiceListing } from "../../../interface/IServiceListing";
import { ILocation } from "../../../interface/ILocations";
import { ICategory } from "../../../interface/ICategory";
import { CommonView, handleSubmit } from "./CommonView";
import { selectAuthUser, selectStrapi } from "../../constraints";
import { convertCategory, convertService } from "../../modules/Strapi/ApiConverter";
import {
  StrapiCategoryResponse,
  StrapiIndustryResponse,
  StrapiLocationResponse,
  StrapiServiceResponse
} from "../../modules/Strapi/types/StrapiResponses";
import { convertRelation } from "../../modules/Strapi/Func";

import {
  FileInput,
  MultiDropSelect,
  RadioSelect,
  SingleDropSelect,
  TextArea,
  TextField
} from "../../../components/FormFields";

import FormAlertMessage, { FormAlertMessageProps } from "../../../components/FormMessages/FormAlertMessage";
import FormDeclinedMessage from "../../../components/FormMessages/FormDeclinedMessage";

export const ServiceView = (props: any) => {
  const user = useSelector(selectAuthUser, shallowEqual);
  const strapi = useSelector(selectStrapi);

  // useSubheader().setTitle("Listing");

  const [query] = useState(window.location.search);

  const [isLoaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const [listing, setListing] = useState<IServiceListing>({} as any);

  const [message, setMessage] = useState<FormAlertMessageProps>({ type: "info", message: "" });
  const [redirectTo, redirect] = useState("");
  const [nzOwned, setNzOwned] = useState<boolean>(false);

  const [listingStatus, setListingStatus] = useState("PENDING");

  const { initialValues, Schema } = CommonView("services");
  // const [apps, setApps] = useState<IAppListing[]>([]);

  type StrapiData = {
    industries: IIndustry[];
    selectedIndustry: IIndustry;

    categories: ICategory[];
    selectedCategory: ICategory;

    locations: ILocation[];
    selectedLocations: ILocation[];
  }

  const defaultIndustry: IIndustry = {
    id: 0,
    url: "",
    name: ""
  };

  const defaultCategory: ICategory = {
    id: 0,
    url: "",
    name: "",
    subcategories: []
  };

  const [strapiData, setStrapiData] = useState<StrapiData>({
    industries: [] as IIndustry[],
    selectedIndustry: defaultIndustry,

    categories: [] as ICategory[],
    selectedCategory: defaultCategory,

    locations: [] as ILocation[],
    selectedLocations: [] as ILocation[]
  });

  const updateStrapiData = (data: Partial<StrapiData>) => {
    setStrapiData({
      ...strapiData,
      ...data
    });
  };

  const fetchData = async () => {
    const convert = {
      industries: (value?: StrapiIndustryResponse[]) => convertRelation({ data: value }, "industries"),
      categories: (value?: StrapiCategoryResponse[]) => (value ?? []).map(convertCategory),
      locations: (value?: StrapiLocationResponse[]) => convertRelation({ data: value }, "locations")
    };

    const industries = strapi.collection("industries")
      .getAll<StrapiIndustryResponse>()
      .then(convert.industries);

    const categories = strapi.collection("categories")
      .getAll<StrapiCategoryResponse>()
      .then(convert.categories);

    const locations = strapi.collection("locations")
      .getAll<StrapiLocationResponse>()
      .then(convert.locations);

    // getListings("apps", convertApp, { legacyPath: "/apps/" })("").then(setApps);

    await Promise.all([industries, categories, locations])
      .then(([industries, categories, locations]) => {
        setStrapiData({
          ...strapiData,
          industries,
          categories,
          locations
        });

        if (!isNew && listingId) {
          return strapi.collection("service")
            .getCustom<StrapiServiceResponse>(listingId) //NB: Listing id in this case is the slug
            .then(convertService)
            .then(service => {
              const newState = {
                industries,
                categories,
                locations,
                selectedIndustry: defaultIndustry,
                selectedCategory: defaultCategory,
                selectedLocations: []
              } as StrapiData;

              if (service.industries && service.industries.length > 0) {
                newState.selectedIndustry = industries.find(i => service.industries[0].id === i.id) as IIndustry;
              }

              if (service.categories && service.categories.length > 0) {
                newState.selectedCategory = categories.find(c => service.categories[0].id === c.id) as ICategory;
              }

              if (service.locations && service.locations.length > 0) {
                newState.selectedLocations = service.locations;
              }

              const newValues = {
                ...service,
                industries: [newState.selectedIndustry?.id ?? 0],
                categories: [newState.selectedCategory?.id ?? 0],
                admin_note: "" //app.admin_note || ""
              };

              formik.setValues(newValues as any);
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setListing(newValues);
              setNzOwned(service.nz_owned);
              setListingStatus(service.status as string);
              setLoading(false);

              updateStrapiData(newState);
            });
        }
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // Set the relative items
      if (strapiData.selectedCategory.id) {
        listing.categories = [strapiData.selectedCategory.id.toString()];
      }

      if (strapiData.selectedIndustry.id) {
        listing.industries = [strapiData.selectedIndustry.id.toString()];
      }

      if (strapiData.selectedLocations.length) {
        listing.locations = strapiData.selectedLocations.flatMap(location => location.id.toString());
      }

      handleSubmit({
        strapi,
        formik: formik,
        isNewListing: !listing.url,
        isService: true,
        listing: listing,
        nzOwned: nzOwned,
        setMessage,
        setStatus,
        setSubmitting,
        submitHandler(strapiListing: any) {
          if (strapiListing && strapiListing.attributes && strapiListing.attributes.slug) {
            const { slug } = strapiListing.attributes;
            if (!window.location.href.endsWith(slug)) {
              window.location.href = `/services/pending/view/${slug}`;
              return;
            }
          }

          fetchData();
          if (!listing.url) {
            redirect("/services/pending");
          }
        }
      });
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    }
  });

  // NB: This "ID" is actually the listing slug and not the real id
  // This is due to the legacy system using slugs instead of unique identifiers
  const { listingId } = props;
  const isNew = !listingId;

  const checkDelete = () => {
    if (confirm(`Delete ${listing.name}?`)) {
      setLoading(true);
      strapi.collection("services")
        .delete(listing.id)
        .then(() => {
          redirect(`/services/${props.isPending ? "pending" : "active"}`);
        });
    }
  };

  // Load the page
  React.useEffect(() => {
    if (!isLoaded) {
      setLoaded(true);
      fetchData().then(() => {
        setLoading(false);
      });
    }
  }, [listingId, formik, isLoaded, strapi, user]);


  React.useEffect(() => {
    if (formik.values.status !== listingStatus) {
      formik.setFieldValue("status", listingStatus);
    }
  }, [listingStatus]);


  if (isLoaded && isNew && !user.admin && !user.upgraded) {
    return <Redirect to="/paywall/services" />;
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  if (loading || !listing) {
    return <Loading />;
  }

  return (
    <>
      <form
        className="card card-custom mb-0"
        onSubmit={formik.handleSubmit}
        id="listing-form"
      >
        {/* begin::Header */}
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label font-weight-bolder text-dark">
              Listing Information
            </h3>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
              Information about this listing
            </span>
          </div>
          <div className="card-toolbar">
            <button
              type="submit"
              className="btn btn-success mr-2"
              disabled={
                formik.isSubmitting || (formik.touched && !formik.isValid)
              }
            >
              {isNew ? (user.admin ? "Create listing" : "Submit for review") : "Save changes"}
              {formik.isSubmitting}
            </button>
            <Link
              to={`/services/active${query}`}
              className="btn btn-secondary"
            >
              Cancel
            </Link>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Form */}
        <div className="form">
          {/* begin::Body */}
          <div className="card-body">
            <FormAlertMessage {...message} />
            <FormDeclinedMessage admin_note={listing.admin_note} status={listing.status} />

            {/*{*/}
            {/*  (listing.special_offers || [])*/}
            {/*    .filter((s) => s.status === "D")*/}
            {/*    .map((specialOffer) =>*/}
            {/*      <div className={`mb-10 alert alert-custom alert-danger alert-dismissible`} key={specialOffer.url}>*/}
            {/*        <div className="alert-text font-weight-bold">*/}
            {/*          <div>The special offer has been declined by an*/}
            {/*            admin. {listing.admin_note ? "The following note was left:" : "No note was left."}</div>*/}
            {/*          <div>{specialOffer.admin_note}</div>*/}
            {/*        </div>*/}
            {/*      </div>)*/}
            {/*}*/}

            <TextField label={"Listing name"} id={"name"} formik={formik} />

            <FileInput label={"Logo"} id={"logo"} formik={formik} />

            <TextField label={"Short description"} id={"short_description"} formik={formik} />

            <TextArea label={"Full description"} id={"long_description"} formik={formik} />

            <TextField label={"Link"} id={"link"} formik={formik} type={"url"} />
            <TextField label={"Free trial"} id={"free_trial"} formik={formik} type={"url"} />

            <TextField label={"Pricing"} id={"pricing"} formik={formik} placeholder={"$29.99/month"} />
            <TextField label={"Email"} id={"email"} formik={formik} type={"email"} />

            <TextField label={"Phone"} id={"phone"} formik={formik} type={"tel"} />

            <SingleDropSelect
              id={"industries"}
              items={strapiData.industries}
              label={"Industries"}
              selectedItem={strapiData.selectedIndustry}
              setSelectedItem={(item: IIndustry) => updateStrapiData({ selectedIndustry: item })}
              formik={formik}
            />

            <RadioSelect
              id={"categories"}
              label={"Category"}
              items={strapiData.categories}
              selectedItem={strapiData.selectedCategory}
              setSelectedItem={(item: ICategory) => updateStrapiData({ selectedCategory: item })}
              formik={formik}
            />

            <MultiDropSelect
              id={"locations"}
              items={strapiData.locations}
              label={"Locations"}
              selectedItem={strapiData.selectedLocations}
              setSelectedItem={(item: ILocation[]) => updateStrapiData({ selectedLocations: item })}
              formik={formik}
            />


            {/*<div className="form-group row">*/}
            {/*  <label className="col-xl-3 col-lg-3 col-form-label">*/}
            {/*    Apps*/}
            {/*    <br />*/}
            {/*    <em className="text-muted">Max 10</em>*/}
            {/*  </label>*/}
            {/*  <div className="col-lg-9">*/}
            {/*    <Select*/}
            {/*      className="basic-single mb-0"*/}
            {/*      classNamePrefix="select"*/}
            {/*      isLoading={apps.length === 0}*/}
            {/*      isClearable={false}*/}
            {/*      isSearchable={true}*/}
            {/*      isMulti={true}*/}
            {/*      closeMenuOnSelect={false}*/}
            {/*      hideSelectedOptions={false}*/}
            {/*      options={*/}
            {/*        (listing.apps || []).length >= 10*/}
            {/*          ? []*/}
            {/*          : apps*/}
            {/*            .map((app) => ({*/}
            {/*              label: app.name,*/}
            {/*              value: app.url*/}
            {/*            }))*/}
            {/*      }*/}
            {/*      value={*/}
            {/*        apps*/}
            {/*          .filter((app) => (listing.apps || []).includes(app.url))*/}
            {/*          .map((app) => ({*/}
            {/*            label: app.name,*/}
            {/*            value: app.url*/}
            {/*          }))*/}
            {/*      }*/}
            {/*      onChange={(values) => {*/}
            {/*        const newApps = values ? values.map((value) => value.value) : [];*/}
            {/*        setListing({*/}
            {/*          ...listing,*/}
            {/*          apps: newApps*/}
            {/*        });*/}
            {/*      }}*/}
            {/*      noOptionsMessage={() => {*/}
            {/*        if ((listing.apps || []).length >= 10) {*/}
            {/*          return "Max 10 already selected";*/}
            {/*        }*/}

            {/*        return "No results found";*/}
            {/*      }}*/}
            {/*    />*/}

            {/*    {apps && apps.map((app) => {*/}
            {/*      const checked = ((listing.apps || []) as any as string[]).includes(app.url);*/}
            {/*      return (*/}
            {/*        <input*/}
            {/*          key={app.url}*/}
            {/*          className="d-none"*/}
            {/*          type="checkbox"*/}
            {/*          name="apps"*/}
            {/*          value={app.url}*/}
            {/*          checked={checked}*/}
            {/*          onChange={() => {*/}
            {/*            // null*/}
            {/*          }}*/}
            {/*        />*/}
            {/*      );*/}
            {/*    })}*/}

            {/*    {touched.apps && errors.apps ? (*/}
            {/*      <div className="invalid-feedback">*/}
            {/*        {errors.apps}*/}
            {/*      </div>*/}
            {/*    ) : null}*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                NZ Owned
              </label>
              <div className="col-lg-9">
                <label className="mb-0 alert alert-custom alert-outline-info">
                  <div className="alert-text font-weight-bold">
                    <label className="d-flex align-items-center m-0">
                      <label className="checkbox checkbox-lg flex-shrink-0 m-0 mr-4">
                        <input
                          type="checkbox"
                          name="nz_owned"
                          onChange={() => {
                            setNzOwned(!nzOwned);
                          }}
                          checked={nzOwned}
                        />
                        <span></span>
                      </label>
                      <div className="d-flex flex-column flex-grow-1">
                        <span>This listing is New Zealand owned and operated</span>
                      </div>
                    </label>
                  </div>
                </label>
              </div>
            </div>

            {user && user.admin && listing.status &&
              <>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Status
                  </label>
                  <div className="col-lg-9">
                    <input type="hidden" {...formik.getFieldProps("status")} />
                    {
                      [
                        ["Pending", "P", "primary"],
                        ["Approved", "A", "success"],
                        ["Declined", "D", "danger"]
                      ].map(([title, code, className]) =>
                        <button key={code} type="button"
                                className={`btn btn-${listingStatus === code ? className : "secondary"} mr-3`}
                                onClick={() => setListingStatus(code)}>{title}</button>
                      )
                    }
                  </div>
                </div>

                {listingStatus === "DECLINED" &&
                  (
                    <TextArea label={"Reason for decline"} id={"admin_note"} formik={formik} />
                  )
                }
              </>
            }
          </div>
          {/* end::Body */}
        </div>
        {/* end::Form */}
      </form>

      {listing && listing.url &&
        <div className="card card-custom mt-5">
          {/* begin::Header */}
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark">
                Danger Zone
              </h3>
              <span className="text-muted font-weight-bold font-size-sm mt-1">
                Permanently delete this listing
              </span>
            </div>
            <div className="card-toolbar">
              <button
                type="button"
                onClick={checkDelete}
                className="btn btn-danger">
                {"Delete"}
              </button>
            </div>
          </div>
          {/* end::Header */}
        </div>
      }
    </>
  );
};
