import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { Content } from "./Content";

export interface IContentRouteProps extends RouteProps {
  children?: React.ReactNode;
  component?: React.FC;
  render?: (props: any) => React.ReactNode;
}

export function ContentRoute({ children, component, render, ...props }: IContentRouteProps): React.ReactElement {
  return (
    <Route {...props}>
      {routeProps => {
        if (typeof children === "function") {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return <Content>{children(routeProps)}</Content>;
        }

        if (!routeProps.match) {
          return null;
        }

        if (children) {
          return <Content>{children}</Content>;
        }

        if (component) {
          return (
            <Content>{React.createElement(component, routeProps as any)}</Content>
          );
        }

        if (render) {
          return <Content>{render(routeProps)}</Content>;
        }

        return null;
      }}
    </Route>
  );
}
