import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getListing } from "../../modules/Auth/_redux/authCrud";
import { IAppListing } from "../../../interface/IAppListing";
import { IServiceListing } from "../../../interface/IServiceListing";
import { Redirect } from "react-router-dom";
import Loading from "../../Loading";
import moment from "moment";

import { AnalyticsChart } from "../../../_metronic/_partials/widgets/stats/AnalyticsChart";

export const AnalyticsView = (props: any) => {
  const { listingId, listingType, isPending } = props;

  const user = useSelector(({ auth }: any) => auth.user, shallowEqual);
  useEffect(() => {
    return () => {
      // null
    };
  }, [user]);
  const isPremium = user.admin || user.upgraded;
  const showPaywall = !isPremium && listingType === "services";

  // useSubheader().setTitle("Analytics");

  const [loading, setLoading] = useState(true);
  const [listing, setListing] = useState<IAppListing | IServiceListing>({} as any);
  const [thirtyDayData, setThirtyDayData] = useState<Array<{
    date: string,
    value: number,
  }>>([]);
  const [sevenDayData, setSevenDayData] = useState<Array<{
    date: string,
    value: number,
  }>>([]);
  const [lastSevenDayData, setLastSevenDayData] = useState<Array<{
    date: string,
    value: number,
  }>>([]);

  const getAnalyticsData = React.useCallback((startDate: moment.Moment, endDate: moment.Moment) => {
    return getListing<any>(`/analytics${isPending ? "/pending" : ""}/${listingType}/${listingId}?start_date=${startDate.format("YYYY-MM-DD")}&end_date=${endDate.format("YYYY-MM-DD")}`)
      .then((res) => {
        const analyticsDataToStore = [];
        for (let m = moment(startDate); m.diff(endDate, 'days') <= 0; m.add(1, "days")) {
          const thisDate = m.format("YYYY-MM-DD");
          analyticsDataToStore.push({
            date: thisDate,
            value: res.data.views[thisDate] || 0,
            label: m.format("MMMM Do, YYYY")
          });
        }
        return analyticsDataToStore;
      });
  }, []);

  React.useEffect(() => {
    if (!showPaywall) {
      getListing(`${isPending ? "/pending" : ""}/${listingType}/${listingId}`)
        .then((res) => {
          setListing(res);
          setLoading(false);

          getAnalyticsData(
            moment().subtract(30, "days"),
            moment())
            .then((res) => {
              setThirtyDayData(res);
            });

          getAnalyticsData(
            moment().subtract(7, "days"),
            moment())
            .then((res) => {
              setSevenDayData(res);
            });

          getAnalyticsData(
            moment().subtract(14, "days"),
            moment().subtract(7, "days"))
            .then((res) => {
              setLastSevenDayData(res);
            });
        });
    }
  }, []);

  if (showPaywall) {
    return <Redirect to="/account/subscription/analytics-paywall" />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className={`card card-custom mb-5`}>
        <div className="card-body p-0">
          <div className="card-spacer">
            <h2 className="text-dark-75 font-weight-bolder font-size-h3 m-0">{listing.name}</h2>
          </div>
        </div>
      </div>

      <AnalyticsChart
        id="analytics-30day"
        className="mb-5"
        title="30-Day View"
        description="View per day over the last 30 days."
        analyticsData={[
          {
            title: "Views",
            data: thirtyDayData
          }
        ]}
        unit="views" />

      <AnalyticsChart
        id="analytics-weekvweek"
        className=""
        title="Week Comparison"
        description="Views the last 7 days vs. the 7 prior."
        analyticsData={[
          {
            title: "Last 7 days",
            data: sevenDayData
          },
          {
            title: "7 days prior",
            data: lastSevenDayData
          }
        ]}
        unit="views" />
    </>
  );
};
