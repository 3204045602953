import {
  StrapiAccountResponse,
  StrapiAppResponse,
  StrapiCategoryResponse,
  StrapiManyRelationResponse,
  StrapiReviewResponse,
  StrapiServiceResponse,
  StrapiSubcategoryResponse,
  StrapiTeamMemberResponse,
  StrapiUserResponse
} from "./types/StrapiResponses";
import {
  calculateSlug,
  convertRelation,
  convertRelationArr,
  getFirstStrapiImageUrl,
  getListingRating,
  getTeamMembers
} from "./Func";
import {
  ConvertedAppResponse,
  ConvertedCategoryResponse,
  ConvertedReviewResponse,
  ConvertedSubcategoryResponse
} from "./types/ConvertedTypes";
import Strapi from "./Strapi";
import { ITeamMember } from "../../../interface/ITeamMember";

function getFirstAtt<T = any>(obj: any, key: string, defaultValue: T | null = null) {
  const holding = getAtt(obj, key, defaultValue);
  if (Array.isArray(holding)) {
    return holding[0];
  }
  return holding;
}

function getAtt<T = any>(obj: any, key: string, defaultValue: T | null = null) {
  if (obj && obj.attributes && key in obj.attributes) {
    return obj[key];
  }

  return defaultValue;
}

export function convertReview(type: "apps" | "services", review: StrapiReviewResponse) {
  return {
    id: review.id,
    url: `${process.env.REACT_APP_STRAPI_URL}/reviews/${type}/${review.id}/`,
    created_by: review.attributes.createdBy ?? "",
    rating: review.attributes.rating ?? 0,
    comment: review.attributes.comment ?? "",
    note: review.attributes.comment ?? ""
  } as ConvertedReviewResponse;
}

export async function convertApp(app: StrapiAppResponse) {
  const reviews: ConvertedReviewResponse[] = [];
  if (app.attributes.reviews) {
    (app.attributes.reviews as StrapiManyRelationResponse<StrapiReviewResponse>)
      .data.forEach((review: StrapiReviewResponse) => {
      reviews.push(convertReview("apps", review));
    });
  }

  let accountId = getFirstAtt(app, "account_id");
  if (app.attributes.account && !accountId) {
    accountId = (app.attributes.account as StrapiUserResponse).id;
  }


  let rating = getAtt(app, "rating");
  if (rating === null) {
    rating = await getListingRating(app.id);
  }

  return {
    id: app.id,
    url: calculateSlug(app, "apps"),
    status: app.attributes.status,
    name: app.attributes.name ?? "",
    logo: getFirstStrapiImageUrl(app.attributes.logo),
    rating: rating,
    short_description: app.attributes.summary ?? "",
    long_description: app.attributes.description ?? "",
    pricing: app.attributes.pricing ?? "",
    num_reviews: reviews.length,
    subcategories: convertRelationArr(app.attributes.subcategories, convertSubcategory),
    nz_owned: app.attributes.nz ?? false,
    special_offers: [],
    reviews: reviews,
    featured: false,
    industries: convertRelation(app.attributes.industries, "industries"),
    link: app.attributes.link,
    free_trial: app.attributes.trial,
    pending_special_offers: null,
    created_by: accountId
  } as ConvertedAppResponse;
}

export async function convertService(service: StrapiServiceResponse) {
  const apps: Promise<ConvertedAppResponse>[] = [];
  if (service.attributes.apps) {
    (service.attributes.apps as StrapiManyRelationResponse<StrapiAppResponse>)
      .data.forEach(function(app: StrapiAppResponse) {
      apps.push(convertApp(app));
    });
  }


  const reviews: ConvertedReviewResponse[] = [];
  if (service.attributes.reviews) {
    (service.attributes.reviews as StrapiManyRelationResponse<StrapiReviewResponse>)
      .data.forEach((review: StrapiReviewResponse) => {
      reviews.push(convertReview("services", review));
    });
  }

  return {
    id: service.id,
    url: calculateSlug(service, "services"),
    status: service.attributes.status,
    created_at: service.attributes.createdAt,
    created_by: service.attributes.email,
    name: service.attributes.name,
    slug: service.attributes.slug,
    logo: getFirstStrapiImageUrl(service.attributes.logo),
    rating: await getListingRating(service.id, "service"),
    free_trial: service.attributes.trial,
    short_description: service.attributes.summary,
    long_description: service.attributes.description,
    email: service.attributes.email,
    phone: service.attributes.phone,
    link: service.attributes.link,
    pricing: service.attributes.pricing,
    num_reviews: reviews.length,
    featured: service.attributes.featured,
    locations: convertRelation(service.attributes.locations, "locations"),
    nz_owned: service.attributes.nz,
    categories: convertRelation(service.attributes.categories, "categories"),
    apps: await Promise.all(apps),
    industries: convertRelation(service.attributes.industries, "industries"),
    special_offers: [],
    pending_special_offers: [],
    reviews: reviews,
    team: await getTeamMembers(service.id)
  };
}

export function convertUser(user: StrapiUserResponse & StrapiAccountResponse) {
  return {
    id: user.django_id ?? user.id,
    username: user.email,
    email: user.email,
    first_name: user.first_name ?? "",
    last_name: user.last_name,
    admin: user.super ?? user.staff ?? false,
    verified_email: user.confirmed,
    customer_id: user.customer_id ?? null,
    subscription_id: user.subscription_id ?? null,
    redirect_url: user.redirect_url ?? "",
    upgraded: false,
    trialing: false,
    newsletter: user.newsletter ?? false
  };
}

export function convertTeam(teamMember: StrapiTeamMemberResponse) {
  return {
    id: teamMember.id,
    name: teamMember.attributes.name,
    position: teamMember.attributes.position,
    description: teamMember.attributes.description,
    photo: getFirstStrapiImageUrl(teamMember.attributes.photo)
  } as ITeamMember;
}

export function convertCategory(category: StrapiCategoryResponse) {
  return {
    id: category.id,
    name: category.attributes.name,
    url: calculateSlug(category, "categories") ?? "",
    subcategories: convertRelation(category.attributes.subcategories, "subcategories")
      .map((subcategory) => ({
        ...subcategory,
        category: category.attributes.name,
        category_id: category.id
      }))
  } as ConvertedCategoryResponse;
}

export function convertSubcategory(subcategory: StrapiSubcategoryResponse) {
  let categoryId = 0, categoryName = "", subCategoryName = "";

  if (subcategory.attributes) {
    const cat = Strapi.flattenFirstStrapiObj(subcategory.attributes.category);
    categoryId = cat.id;
    categoryName = cat.name;

    subCategoryName = subcategory.attributes.name;
  } else if ("name" in subcategory) {
    const sub = subcategory as Record<string, any>;
    subCategoryName = sub.name;
    categoryId = sub.category.id;
    categoryName = sub.category.name;
  }


  return {
    id: subcategory.id,
    name: subCategoryName,
    url: calculateSlug(subcategory, "subcategories") ?? "",
    category: categoryName,
    category_id: categoryId
  } as ConvertedSubcategoryResponse;
}