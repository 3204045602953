import moment from "moment";
import React, { useState } from "react";
import { AnalyticsChart } from "../../_metronic/_partials/widgets/stats/AnalyticsChart";
import Loading from "../Loading";

import { getListing } from "../modules/Auth/_redux/authCrud";
import { ANALYTICS_ENABLED } from "../constraints";

interface IAnalyticsData {
  apps: number;
  services: number;
  users: number;
}

interface IAnalyticsBlock {
  title: string;
  value: () => number;
}

export const DashboardView = () => {
//  useSubheader().setTitle("Analytics");

  if (!ANALYTICS_ENABLED) {
    window.location.pathname = "/apps/active?user=me";
    return <Loading />;
  }


  const [analyticsData, setAnalyticsData] = useState<IAnalyticsData | null>(null);
  const [userData, setUserData] = useState<Array<{
    date: string,
    value: number,
    rollingTotal: number,
  }>>([]);

  const [loading, setLoading] = useState(true);

  const getAnalyticsData = React.useCallback((endpoint: string) => {
    return getListing<any>(endpoint)
      .then((res) => {
        const dates = Object.keys(res.counts);

        const startDate = dates[0];
        const endDate = dates[dates.length - 1];

        const analyticsDataToStore = [];
        let rollingTotal = 0;
        for (let m = moment(startDate); m.diff(endDate, 'days') <= 0; m.add(1, "days")) {
          const thisDate = m.format("YYYY-MM-DD");

          const count = res.counts[thisDate] || 0;
          rollingTotal += count;

          analyticsDataToStore.push({
            date: thisDate,
            value: count,
            rollingTotal: rollingTotal,
            label: m.format("MMMM Do, YYYY")
          });
        }
        return analyticsDataToStore;
      });
  }, []);

  React.useEffect(() => {
    getListing<any>("/analytics/")
      .then((res) => {
        setAnalyticsData(res.counts);
        setLoading(false);
      }).catch(() => localStorage.clear());

    getAnalyticsData("/analytics/users/")
      .then((res) => {
        setUserData(res);
      });
  }, []);

  if (loading || !analyticsData) {
    return <Loading />;
  }

  const analyticsRows: IAnalyticsBlock[][] = [
    [
      {
        title: "Total amount of app listings",
        value: () => analyticsData.apps
      },

      {
        title: "Total amount of cloud experts",
        value: () => analyticsData.services
      }
    ]
  ];

  return (
    <>
      {
        analyticsRows.map((analyticsColumns, i) => (
          <div className="row" key={i}>
            {
              analyticsColumns.map((block, b) => (
                <div className="col-sm mb-5" key={b}>
                  <div className="card card-custom">
                    <div className="card-body d-flex flex-column p-0">
                      <div className="align-items-center justify-content-between card-spacer flex-grow-1">
                        <div className="d-flex flex-column mr-2">
                          <span
                            className="text-dark-75 text-hover-primary font-weight-bolder font-size-h1">{block.value()}</span>
                          <span className="text-muted font-weight-bold mt-2">{block.title}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        ))
      }

      <AnalyticsChart
        id="analytics-userTotals"
        className="mb-5"
        title={analyticsData.users}
        description="Total amount of registered users."
        analyticsData={userData && [
          {
            title: "New users",
            data: userData
          },
          {
            title: "Total users",
            data: userData.map((u) => ({
              date: u.date,
              value: u.rollingTotal
            }))
          }
        ]}
        unit="users" />
    </>
  );
};
