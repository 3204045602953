import React from "react";

export type FormAlertMessageProps = {
  message?: string;
  type?: string;
}
export default function FormAlertMessage(props: FormAlertMessageProps): React.JSX.Element {
  if (!props.type || !props.message) return (<></>);

  return (
    <div className={`mb-10 alert alert-custom alert-light-${props.type} alert-dismissible`}>
      <div className="alert-text font-weight-bold">{props.message}</div>
    </div>
  );
}