import { AnalyticsView } from "./AnalyticsView";
import React, { ComponentType } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ANALYTICS_ENABLED } from "../../constraints";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";

export type FetchListingFn = ((query: string) => Promise<any | any[]>) | (() => Promise<any | any[]>)

export interface IListingCollectionProps {
  collectionType: "apps" | "services";
  listingId?: string;
  listingListPage: ComponentType<any>;
  listingDetailPage: ComponentType<any>;
  defaultQuery: string | null;

  allPendingFn: FetchListingFn;
  pendingFn: FetchListingFn;

  allActiveFn: FetchListingFn;
  activeFn: FetchListingFn;
}

// This component presents the UI elements and navigation for listing the collections
export default function ListingCollection(props: IListingCollectionProps) {
  if (!props) {
    // Handle error here...
    throw new Error("Props are undefined or null");
  }

  // Destructure the props for ease of use
  const {
    collectionType,
    listingId,
    listingListPage,
    listingDetailPage,
    defaultQuery = "",

    allPendingFn,
    pendingFn,

    allActiveFn,
    activeFn
  } = props;

  // Store the user object from the Redux store
  const user = useSelector((state: any) => state.auth.user, shallowEqual);

  // Define paths for routing
  const paths = {
    newListing: `/${collectionType}/new`,
    pending: {
      view: `/${collectionType}/pending/view/:listingId`,
      analytics: `/${collectionType}/pending/analytics/:listingId`,
      admin: `/${collectionType}/pending/all`,
      all: `/${collectionType}/pending`
    },
    active: {
      view: `/${collectionType}/active/view/:listingId`,
      analytics: `/${collectionType}/active/analytics/:listingId`,
      admin: `/${collectionType}/active/all`,
      all: `/${collectionType}/active`
    }
  };

  // ListViewPropsProps is a type used to define the shape of an object used on the Listings list views
  type ListViewPropsProps = {
    key: string,
    title: string,
    caption: string,
    linkText?: string,
    fn: FetchListingFn,
    links?: "active" | "pending",
    linkTo?: "active" | "pending",
  }

  // listViewPropsProps function generates properties for list view components
  const listViewPropsProps = ({
                                key,
                                title,
                                caption,
                                fn,
                                linkText = "Active listings",
                                linkTo = "active",
                                links = "pending"
                              }: ListViewPropsProps) => {

    return {
      key,
      title,
      caption,
      linkText,
      fn,

      createLink: (query: string) => `/${collectionType}/new${query}`,
      linkTo: (query: string) => `/${collectionType}/${linkTo}${query}`,
      viewLink: (id: string) => `/${collectionType}/${links}/view/${id}`,
      analyticsLink: (id: string) => `/${collectionType}/${links}/analytics/${id}`,
      query: window.location.search || defaultQuery
    };
  };

  type RenderWithPropsProps = Record<string, any>

  // renderWithProps is a helper function that takes a component and props, and returns a component with those props
  const renderWithProps = (Element: React.ComponentType<any>, props: RenderWithPropsProps) => {
    return (
      <Element {...props} />
    );
  };

  const getListingId = () => {
    if (listingId) {
      return listingId;
    }
    const splitUrl = location.pathname.split("/");
    return (splitUrl[splitUrl.length - 1] || splitUrl[splitUrl.length - 2]) ?? null;
  };

  // Return a set of components (list / details / pending / active) based on the props and state
  return (
    <Switch>
      {/* New listing */}
      <Route exact path={paths.newListing} render={() => renderWithProps(listingDetailPage, {})} />

      {/* Pending Listings */}
      {getListingId() &&
        <Route
          exact
          path={paths.pending.view}
          render={() => renderWithProps(listingDetailPage, {
            listingId: getListingId(),
            isPending: true
          })}
        />
      }

      {getListingId() && ANALYTICS_ENABLED &&
        <Route
          exact
          path={paths.pending.analytics}
          render={() => renderWithProps(AnalyticsView, {
            listingId,
            listingType: collectionType,
            isPending: true
          })}
        />
      }

      {user && user.admin &&
        <Route
          exact
          path={paths.pending.admin}
          render={() => renderWithProps(
            listingListPage,
            listViewPropsProps({
              key: paths.pending.admin,
              title: "All Pending Listings",
              caption: "Listings requiring approval",
              fn: allPendingFn
            })
          )}
        />
      }

      <Route
        exact
        path={paths.pending.all}
        render={() => renderWithProps(
          listingListPage,
          listViewPropsProps({
            key: paths.pending.all,
            title: "Pending Listings",
            caption: "Listings requiring approval",
            fn: pendingFn
          })
        )}
      />

      {/* Active Listings */}
      {getListingId() &&
        <Route
          exact
          path={paths.active.view}
          render={() => renderWithProps(listingDetailPage, {
            listingId: getListingId(),
            isPending: false
          })}
        />
      }

      {getListingId() && ANALYTICS_ENABLED &&
        <Route
          exact
          path={paths.active.analytics}
          render={() => renderWithProps(AnalyticsView, {
            listingId: getListingId(),
            listingType: collectionType,
            isPending: false
          })}
        />
      }

      {user && user.admin &&
        <Route
          exact
          path={paths.active.admin}
          render={() => renderWithProps(
            listingListPage,
            listViewPropsProps({
              key: paths.active.admin,
              title: "All Listings",
              caption: "All listings on The Right Tool homepage",
              linkText: "View pending",
              linkTo: "pending",
              links: "active",
              fn: allActiveFn
            })
          )}
        />
      }

      <Route
        exact
        path={paths.active.all}
        render={() => renderWithProps(
          listingListPage,
          listViewPropsProps({
            key: paths.active.all,
            title: "Active Listings",
            caption: "Your active listings on The Right Tool homepage",
            linkTo: "pending",
            linkText: "View pending",
            links: "active",
            fn: activeFn
          })
        )}
      />
    </Switch>
  );
}

ListingCollection.propTypes = {
  collectionType: PropTypes.oneOf(["apps", "services"]).isRequired,
  listingId: PropTypes.string,
  listingListPage: PropTypes.elementType.isRequired,
  listingDetailPage: PropTypes.elementType.isRequired,
  defaultQuery: PropTypes.string,
  allPendingFn: PropTypes.func.isRequired,
  pendingFn: PropTypes.func.isRequired,
  allActiveFn: PropTypes.func.isRequired,
  activeFn: PropTypes.func.isRequired
};

