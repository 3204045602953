import React, { useState } from "react";
import { useFormik } from "formik";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { selectStrapi } from "../../../constraints";

const initialValues = {
  email: ""
};

function ForgotPassword (props) {
  const { intl } = props;
  const strapi = useSelector(selectStrapi);

  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email(
      intl.formatMessage({
        id: "AUTH.VALIDATION.INVALID_EMAIL"
      })
    ).min(3, "Minimum 3 symbols").max(50, "Maximum 50 symbols").required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD"
      })
    )
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      strapi.forgotPasswordRequest(values.email).then((response) => {
        setIsRequested(true);
        setStatus(
          intl.formatMessage(
            { id: "AUTH.FORGOT.SENT" },
            { email: values.email }
          )
        );
      }).catch(() => {
        setIsRequested(false);
        setSubmitting(false);
        setStatus(
          intl.formatMessage(
            { id: "AUTH.FORGOT.ERROR" }
          )
        );
      });
    }
  });

  return (
    <>
      <div className="login-form login-forgot" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.FORGOT.TITLE" />
          </h3>
          <div className="text-muted font-weight-bold">
            <FormattedMessage id="AUTH.FORGOT.DESC" />
          </div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          {formik.status && (
            <div className={`mb-10 alert alert-custom alert-light-${isRequested
              ? "info"
              : "danger"} alert-dismissible`}>
              <div className="alert-text font-weight-bold">
                {formik.status}
              </div>
            </div>
          )}

          {isRequested &&
            <div className="form-group d-flex flex-wrap flex-center">
              <Link to={`/auth/login${window.location.search}`}>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Back
                </button>
              </Link>
            </div>
          }

          {!isRequested &&
            <>
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="me@domain.com"
                  type="email"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "email"
                  )}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                ) : null}
              </div>
              <div className="form-group d-flex flex-wrap flex-center">
                <button
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  disabled={formik.isSubmitting}
                >
                  Submit
                </button>
                <Link to={`/auth/login${window.location.search}`}>
                  <button
                    type="button"
                    id="kt_login_forgot_cancel"
                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  >
                    Cancel
                  </button>
                </Link>
              </div>
            </>
          }
        </form>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
