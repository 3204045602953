import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import Cookies from "../../../services/Cookies";
import Store, { getStoreItem } from "../../../services/Store";
import { User } from "../../Strapi/User";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  UserLoaded: "[Load User] Auth API",
  StrapiConnected: "[Strapi] Connected",
  PlansLoaded: "[Load Plans] Strapi"
};

const initialAuthState = {
  user: undefined,
  authToken: getStoreItem("AUTH_TOKEN"),
  strapi: undefined,
  plans: undefined
};

export const reducer = persistReducer(
  { storage, key: "v705-auth", whitelist: [""] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        return null;
      }

      case actionTypes.Logout: {
        User.logout();

        Cookies.remove("AUTH_TOKEN");
        Store.clear();
        window.API_TOKEN = undefined;

        return { authToken: null };
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        gtag("set", "user_properties", {
          user_id: user.id
        });
        return { ...state, user };
      }

      case actionTypes.StrapiConnected: {
        const { strapi } = action.payload;
        return { ...state, strapi };
      }

      case actionTypes.PlansLoaded: {
        const { plans } = action.payload;
        return { ...state, plans };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  logout: () => ({ type: actionTypes.Logout }),

  connectUser: user => ({
    type: actionTypes.UserLoaded,
    payload: { user }
  }),

  connectStrapi: strapi => ({
    type: actionTypes.StrapiConnected,
    payload: { strapi }
  }),
  savePlans: plans => ({
    type: actionTypes.PlansLoaded,
    payload: { plans }
  })
};

export function * saga () {

}
