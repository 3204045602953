import Strapi from "./Strapi";
import { API_BASE } from "../../constraints";
import { StrapiImageResponse } from "./types/StrapiResponses";
import { encode } from "blurhash";
import _ from "lodash";
import qs from "qs";
import { StrapiBase } from "./StrapiBase";

export class Upload extends StrapiBase {
  private readonly strapi: Strapi;
  private readonly filesUrl = "/api/upload/files";
  private readonly baseUrl = "/api/upload";
  private readonly sizes = [320, 320 * 2, 768, 768 * 2, 1024, 1024 * 2, 1366, 1366 * 2, 1920, 1920 * 2];

  public constructor(strapi: Strapi) {
    super();
    this.strapi = strapi;
  }

  public async getByFileName(fileName: string) {
    // Try regular get
    return await this.strapi.axios_connection
      .get(`${this.filesUrl}/${fileName}`)
      .catch(() => {
        // Could not get need to deep filter
        return this.getByFilter({
          name: {
            "$eq": fileName
          }
        });
      })
      .then(image => this.processImageResult(image))
      ;
  }

  public async getByUrl(url: string) {
    return await this.getByFilter({
      url: {
        "$eq": url
      }
    }).then(image => this.processImageResult(image));

  }

  public async get(imageId: number) {
    return await this.strapi.axios_connection
      .get(`${this.filesUrl}/${imageId}?populate=user`)
      .then(response => {
        if (response && response.status === 200) {
          return response.data as StrapiImageResponse;
        }
        throw new Error("Could not get image");
      })
      .then(image => this.processImageResult(image))
      .catch((err) => {
        console.error(err);
        return null;
      });
  }

  public async post(imageFile: File) {
    const url = `${this.baseUrl}`;
    const formData = new FormData();

    formData.append("files", imageFile);

    const response =
      await this.strapi.axios_connection.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

    if (response && response.status === 200 && response.data && response.data.length === 1) {
      return this.processImageResult(response.data[0] as StrapiImageResponse);
    }

    throw new Error("Could not post image, response not received or response status not 200");
  }

  public async postMultiple(imageFiles: File[]) {
    const formData = new FormData();
    imageFiles.forEach((file, index) => {
      formData.append(`files${index}`, file);
    });
    const response = await this.strapi.axios_connection.post(this.baseUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    if (response && response.status === 200 && response.data) {
      return response.data.map((image: StrapiImageResponse) => this.processImageResult(image));
    }

    throw new Error("Could not post image, response not received or response status not 200");
  }

  private async getByFilter(filter: Record<string, Record<string, any>>) {
    const filter_qs = qs.stringify({
      filters: filter
    }, { encodeValuesOnly: true });

    return await this.strapi.axios_connection
      .get(`${this.filesUrl}?${filter_qs}`)
      .then(data => {
        if (data && data.status === 200) {
          return data.data;
        }
      })
      .then(data => {
        if (data.length === 1) {
          return data[0];
        }

        throw new Error("Could not find image");
      });
  }

  private processImageResult(data: StrapiImageResponse) {
    if (data && "url" in data) {
      data.slug = data.url;
      data.url = API_BASE + data.slug;
      this.generateImageTag(data);
      return data;
    }
    return null;
  }

  private generateImageUrls(image: StrapiImageResponse) {
    const originalWidth = image.width;
    const originalHeight = image.height;

    return this.sizes
      .filter(size => size < originalWidth)
      .map(newWidth => {

        // Calculate new height keeping aspect ratio
        const newHeight = Math.round((originalHeight / originalWidth) * newWidth);

        // Generate the url
        return `${image.url}?f=webp&s=${newWidth}x${newHeight}`;
      });
  }

  private generateImageTag(data: StrapiImageResponse) {
    if (data && !data.html) {
      const urls = this.generateImageUrls(data);

      // Prepare blurred placeholder
      let blurhashSvg = "";
      if (data.blurhash && _.isNumber(data.blurhash)) {
        const blurhash = encode(new Uint8ClampedArray(data.blurhash), 16, 16, 4, 3);
        blurhashSvg = `
      <div class="placeholder" style="position: absolute; width: 100%; height: 100%;">
        <img src="data:image/svg+xml;base64,${encodeURIComponent(blurhash)}" style="object-fit: cover;">
      </div>
    `;
      }

      // Start building image tag
      let imgTag = `<picture>`;

      for (let i = 0; i < urls.length; i += 2) {
        const url = urls[i];
        const retinaUrl = urls[i + 1] || url;

        const size = this.sizes[i / 2];
        imgTag += `
      <source 
        media="(max-width: ${size}px)" 
        srcset="${url} 1x, ${retinaUrl} 2x"
        type="${data.mime}">
    `;
      }

      const altText = data.alternativeText ? `alt="${data.alternativeText}"` : "";
      const caption = data.caption ? `title="${data.caption}"` : "";
      imgTag += `<img src="${urls[0]}" ${altText} ${caption} /></picture>`;

      data.html = `
    <div style="position:relative; aspect-ratio: ${data.width / data.height};">
      ${blurhashSvg}
      <div style="position:absolute; top:0; left:0; width:100%; height: 100%;">
        ${imgTag}
      </div>
    </div>
  `.replace(/\s*\n\s*/g, " ");
    }

    return data.html;
  }
}